@use 'utils' as *;

// The base stylings for mg-expansion-panel. This should only be included once.
@mixin expansion-panel() {
  $indicatorThickness: 4px;
  $indicatorPaddingOffest: 4px;
  $sidePadding: 0px;

  .mg-expansion-panel.mat-expansion-panel {
    // ensure corect width for  mg-form-field elements with padding in forms
    // and overlays
    box-sizing: border-box;

    .mat-expansion-panel-body,
    .mat-expansion-panel-header {
      padding-left: $sidePadding;
      padding-right: $sidePadding;
    }

    .mat-expansion-panel-header {
      padding-right: $indicatorPaddingOffest;

      .mat-content {
        // to allow for typographic descent not getting cut-off
        overflow: visible;
      }
    }

    .mat-expansion-indicator::after {
      border-width: 0 $indicatorThickness $indicatorThickness 0;
    }
  }
}

// handles updating the styling for filters panels
@mixin filters-panel() {
  .filters-panel {
    mat-expansion-panel {
      padding-top: 0 !important;
      background: transparent;

      &.mat-expanded {
        overflow: visible;
      }

      &::ng-deep .mat-expansion-panel-body {
        --mg-mat-expansion-panel-body-padding: 0;
      }

      &::ng-deep .mat-expansion-panel-header {
        text-align: center;
        justify-content: center;
        align-items: center;
        .mat-content {
          display: inline-block;
          flex: none;
          margin-right: spacing('3');
        }

        .mat-expansion-indicator {
          display: inline-block;
          height: 30px;
          flex: none;
          transform-origin: center center;

          &:after {
            color: app-color(primary) !important;
          }
        }
      }
    }
    /* Reverse the layout of mat-expansion-panel so the expander is below the filters themselves */
    .reversed-mat-expansion-panel {
      display: flex;
      flex-direction: column-reverse;
    }
  }
}
