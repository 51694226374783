@use 'utils' as *;

@mixin base-form() {
  fieldset,
  .fieldset {
    border: none;
    text-align: left;
    margin: 0;
    padding: 0;

    legend {
      display: block;
      margin-bottom: spacing('4');
      width: 100%;
    }
  }

  .modal-form {
    form {
      font-size: rem-calc(16);
    }

    position: relative;
    padding: spacing('2') 0;

    @include breakpoint(medium) {
      width: rem-calc(650);
    }
  }

  .fieldset-seperator {
    margin: 0;
    border: 0;
    border-top: 1px solid get-grey('100');
    display: block;
  }
}
