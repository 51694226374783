@use '@angular/material' as mat;
@use 'utils' as *;

@mixin page($theme, $titleColor: $md-navyblue, $whiteColor: white) {
  $primary: map-get($theme, primary); //#737373
  $accent: map-get($theme, accent); //#4abcff
  $warn: map-get($theme, warn); //#9c49ff

  mg-page .page-html-content {
    color: mat.get-color-from-palette($primary, 500);

    h1 {
      color: mat.get-color-from-palette($titleColor, 500);
      @include mg-headline();
      line-height: 1.25;
    }
    h2 {
      @include mg-headline2();
      line-height: 1.2;
    }
    h3 {
      @include mg-headline3();
      line-height: 1.2;
    }
    p,
    ul,
    li,
    ol {
      color: mat.get-color-from-palette($primary, 500);
      @include responsiveScaling(13px, 16px);
      line-height: 1.2;
    }
    li {
      margin-bottom: 1em;
    }
    a {
      color: mat.get-color-from-palette($warn, 500);
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }

      .material-icons {
        font-size: 16px;
        vertical-align: middle;
        width: 16px;
        height: 18px;
      }
    }
  }

  mg-page .innerCell {
    border-color: mat.get-color-from-palette($accent, 500);

    h1 {
      color: mat.get-color-from-palette($accent, 500);
    }
  }
}
