@use "sass:math";

@use '../settings' as *;
@use './units' as *;

/**
		Creates dynamic/scaling sizing of a property that starts from a min size
		and goes up to an optionally set max size
		@param $min 					min size in px
		@param $max 		(optional) 	max size in px
		@param $minWidth	(optional)	min-width of viewport for start of
										responsive font units. Note that this
										takes precedence over $responsiveSize
    @param $propName	 (optional)	what property to scale (default: font-size)
    @param $stepCount  (optional) sets amount of breakpoints between min and 
                        widths to make a more gradual change and not have max
                        value achieved early
    @param $portraitOnly (optional) if true will only have breakpoints based off
                          of device widths. By default when at the min width it
                          will be responsive to both height and width of devices
                          until either the max value is reached or max 
                          device viewport width/height.
*/
@mixin responsiveScaling(
  $min,
  $max: false,
  $propName: font-size,
  $minWidth: false,
  $maxWidth: false,
  $stepCount: 4,
  $portraitOnly: false
) {
  $responsiveUnitless: 0;
  $minBreakpoint: 0;

  @if $minWidth {
    $responsiveUnitless: math.div(strip-unit($min), (strip-unit($minWidth) * 0.01));
    $minBreakpoint: $minWidth;
  } @else {
    $minBreakpoint: $iphone5Width;
    $minWidth: $iphone5Width;
    $responsiveUnitless: math.div($min, ($minBreakpoint* 0.01));
  }

  // Use responsive sizing by default
  @include vunit(
    $propName,
    $responsiveUnitless,
    0,
    false,
    false,
    $portraitOnly
  );

  @if $portraitOnly {
    @media (max-width: #{$minBreakpoint}) {
      #{$propName}: $min;
    }
  } @else {
    // use min font-size till a width & height
    @media (orientation: landscape) {
      @media (max-height: #{$minBreakpoint}) {
        #{$propName}: $min;
      }
    }
    @media (orientation: portrait) {
      @media (max-width: #{$minBreakpoint}) {
        #{$propName}: $min;
      }
    }
  }

  @if $max {
    $maxBreakpoint: (math.div($max, $responsiveUnitless) * 100);
    @if $maxWidth {
      // only use the max as is if we won't reach the max size before that maxWidth
      @if $maxWidth < $maxBreakpoint {
        $maxBreakpoint: $maxWidth;
      } @else {
        $minUnitless: strip-unit($min);
        $maxUnitless: strip-unit($max);
        $minWidthUnitless: strip-unit($minWidth);
        $maxWidthUnitless: strip-unit($maxWidth);

        $diff: $maxUnitless - $minUnitless;
        $diffWidths: $maxWidthUnitless - $minWidthUnitless;

        $stepInc: math.div($diff, $stepCount);
        $stepIncWidth: math.div($diffWidths, $stepCount);

        @for $i from 1 through $stepCount - 1 {
          $thisValue: $minUnitless + ($stepInc * $i) * 1px;
          $thisMinWidth: $minWidthUnitless + ($stepIncWidth * $i) * 1px;
          $thisNextValue: $minUnitless + ($stepInc * ($i + 1)) * 1px;

          $thisResponseUnitless: math.div($thisValue, ($thisMinWidth* 0.01));
          $thisMaxBreakpoint: math.div($thisNextValue, $thisResponseUnitless) * 100;

          // add new responsive units
          @media (min-width: #{$thisMinWidth}) {
            @include vunit(
              $propName,
              $thisResponseUnitless,
              0,
              false,
              false,
              $portraitOnly
            );
          }
          // set width at which to be exact (not responsive units)
          @media (min-width: #{$thisMaxBreakpoint}) {
            #{$propName}: $thisValue;
          }
        }

        $maxBreakpoint: $maxWidth;
      }
    }

    // use at a width & height use the max font-size
    @media (min-width: #{$maxBreakpoint}) {
      #{$propName}: $max;
    }
  }
}

/// Sets property to $min if viewport width is less than or equal to $minWidth,
/// sets property to $max if viewport width is greater than or equal to
/// $maxWidth if viewporth width is inbetween $minWidth and $maxWidth the
/// property is set to a value between $min and $max using calc() the `vw` unit
///
/// @param $property - The property to set
/// @param $min - The value the property should be at $minWidth
/// @param $max - The value the property should be at $maxWidth
/// @param $minWidth - The viewport width the property should be at $min
/// @param $maxWidth - The viewport width the property should be at $max
@mixin set-property-vw-responsive($property, $min, $max, $minWidth, $maxWidth) {
  $halfWayWidth: $minWidth + (($maxWidth - $minWidth)*0.5);
  $absRange: abs(strip-unit($max - $min));
  $rangeMultiplier: math.div($maxWidth - $minWidth, $absRange);
  $calcValue: $minWidth + ($absRange * $rangeMultiplier);

  // @TODO: Get this working.

  // @if $minWidth + $range != $maxWidth {
  //   // The math below isn't complete. If this is being hit then we should
  //   // probably complete this mixin.
  //   @error "set-property-vw-responsive max incomplete";
  // }

  // #{$property}: calc(#{$calcValue} - 100vw);

  @media (min-width: $maxWidth) {
    #{$property}: $max;
  }

  @media (max-width: $minWidth) {
    #{$property}: $min;
  }
}
