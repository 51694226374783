@use '@angular/material' as mat;
@use 'utils' as *;

@mixin message {
  mg-message {
    &.mg-message-style-received {
      a {
        color: $linkColor;
      }
    }

    &.mg-message-style-sent {
      a {
        color: white;
      }
    }
  }
}

@mixin message-dialog($theme, $textColor: $md-black) {
  $primary: map-get($theme, primary);

  p {
    color: mat.get-color-from-palette($primary, '600');
  }
  .mg-dialog-message-body {
    @include mg-bodycopy(0.88);
    color: mat.get-color-from-palette($textColor, 500);
  }
}
