// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://www.google.com/design/spec/style/color.html

// created pallete with this tool: http://mcg.mbitson.com/
/* For use in src/lib/core/theming/_palette.scss */

$md-mingablue: (
  50: #e7f9ff,
  100: #c2f0ff,
  200: #99e6ff,
  300: #70dbff,
  400: #52d4ff,
  500: #33ccff,
  600: #2ec7ff,
  700: #27c0ff,
  800: #20b9ff,
  900: #14adff,
  A100: #ffffff,
  A200: #fbfdff,
  A400: #c8eaff,
  A700: #aee1ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$md-purple: (
  50: #f3e9ff,
  100: #e1c8ff,
  200: #cea4ff,
  300: #ba80ff,
  400: #ab64ff,
  500: #9c49ff,
  600: #9442ff,
  700: #8a39ff,
  800: #8031ff,
  900: #6e21ff,
  A100: #ffffff,
  A200: #ffffff,
  A400: #dfd0ff,
  A700: #ceb7ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$md-blue2: (
  50: #e6e7ff,
  100: #c0c3ff,
  200: #969bff,
  300: #6c72ff,
  400: #4d54ff,
  500: #2d36ff,
  600: #2830ff,
  700: #2229ff,
  800: #1c22ff,
  900: #1116ff,
  A100: #ffffff,
  A200: #f8f8ff,
  A400: #c5c6ff,
  A700: #acadff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$md-orange: (
  50: #fff6e0,
  100: #ffe9b3,
  200: #ffdb80,
  300: #ffcc4d,
  400: #ffc126,
  500: #ffb600,
  600: #ffaf00,
  700: #ffa600,
  800: #ff9e00,
  900: #ff8e00,
  A100: #ffffff,
  A200: #fff9f2,
  A400: #ffe0bf,
  A700: #ffd3a6,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$md-navyblue: (
  50: #e0e7ed,
  100: #b3c2d1,
  200: #8099b3,
  300: #4d7094,
  400: #26527d,
  500: #003366,
  600: #002e5e,
  700: #002753,
  800: #002049,
  900: #001438,
  A100: #6e93ff,
  A200: #3b6cff,
  A400: #0846ff,
  A700: #003bee,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$md-disabled: (
  50: #f9f9f9,
  100: #f1f1f1,
  200: #e8e8e8,
  300: #dfdfdf,
  400: #d8d8d8,
  500: #d1d1d1,
  600: #cccccc,
  700: #c6c6c6,
  800: #c0c0c0,
  900: #b5b5b5,
  A100: #ffffff,
  A200: #ffffff,
  A400: #ffffff,
  A700: #fff0f0,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$md-disabled-dark: (
  50: #f1f1f1,
  100: #dddddd,
  200: #c6c6c6,
  300: #afafaf,
  400: #9d9d9d,
  500: #8c8c8c,
  600: #848484,
  700: #797979,
  800: #6f6f6f,
  900: #5c5c5c,
  A100: #fad3d3,
  A200: #f5a5a5,
  A400: #ff6868,
  A700: #ff4e4e,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$md-warning: (
  50: #fbe3e3,
  100: #f5b9b9,
  200: #ef8b8b,
  300: #e85d5d,
  400: #e33a3a,
  500: #de1717,
  600: #da1414,
  700: #d51111,
  800: #d10d0d,
  900: #c80707,
  A100: #fff2f2,
  A200: #ffbfbf,
  A400: #ff8c8c,
  A700: #ff7373,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$md-white: (
  50: white,
  100: white,
  200: white,
  300: white,
  400: white,
  500: white,
  600: white,
  700: white,
  800: white,
  900: white,
  A100: white,
  A200: white,
  A400: white,
  A700: white,
  contrast: (
    50: black,
    100: black,
    200: black,
    300: black,
    400: black,
    500: black,
    600: black,
    700: black,
    800: black,
    900: black,
    A100: black,
    A200: black,
    A400: black,
    A700: black,
  ),
);

// Grey used in components
$md-compgrey: (
  50: #f9f9f9,
  100: #f0f0f0,
  200: #e6e6e6,
  300: #dbdbdb,
  400: #d4d4d4,
  500: #cccccc,
  600: #c7c7c7,
  700: #c0c0c0,
  800: #b9b9b9,
  900: #adadad,
  A100: #ffffff,
  A200: #ffffff,
  A400: #fffcfc,
  A700: #ffe2e2,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$md-compgrey-light: (
  50: #fcfcfc,
  100: #f6f6f6,
  200: #f1f1f1,
  300: #ebebeb,
  400: #e6e6e6,
  500: #e2e2e2,
  600: #dfdfdf,
  700: #dadada,
  800: #d6d6d6,
  900: #cfcfcf,
  A100: #ffffff,
  A200: #ffffff,
  A400: #ffffff,
  A700: #ffffff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

// text placeholder grey
$md-textgrey: (
  50: #efefef,
  100: #d7d7d7,
  200: #bcbcbc,
  300: #a1a1a1,
  400: #8c8c8c,
  500: #787878,
  600: #707070,
  700: #656565,
  800: #5b5b5b,
  900: #484848,
  A100: #f7b4b4,
  A200: #f28686,
  A400: #ff4646,
  A700: #ff2c2c,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$md-black: (
  50: #e6e6e6,
  100: #c1c1c1,
  200: #979797,
  300: #6d6d6d,
  400: #4e4e4e,
  500: #2f2f2f,
  600: #2a2a2a,
  700: #232323,
  800: #1d1d1d,
  900: #121212,
  A100: #ef6b6b,
  A200: #e93d3d,
  A400: #f30000,
  A700: #da0000,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

// grey used in card content for text
$md-cardgrey: (
  50: #eeeeee,
  100: #d5d5d5,
  200: #b9b9b9,
  300: #9d9d9d,
  400: #888888,
  500: #737373,
  600: #6b6b6b,
  700: #606060,
  800: #565656,
  900: #434343,
  A100: #f6acac,
  A200: #f17e7e,
  A400: #ff3d3d,
  A700: #ff2424,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$_mdgradient1: map-get($md-mingablue, 500);
$_mdgradient2: map-get($md-purple, 500);
$mg-gradient: linear-gradient(90deg, $_mdgradient1, $_mdgradient2);
