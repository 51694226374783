@use 'utils' as *;
@import '~@ng-select/ng-select/themes/default.theme.css';

@mixin ngselect {
  .ng-dropdown-panel {
    &.ng-select-bottom {
      margin-top: rem-calc(5);
    }

    border: 1px solid #c9c9c9;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: map-deep-get(
      $minga-input-form-vars,
      'borderRadius',
      'default'
    );
    overflow: hidden;
    margin-top: rem-calc(5);
    .ng-option {
      font-weight: 100;
      text-align: left;
      &-selected {
        background: #e8f7ff !important;
        font-weight: 700;
        color: app-color('surface') !important;
        .ng-option-label {
          color: app-color('surface') !important;
        }
      }
    }
    .ng-dropdown-panel-items {
      .ng-option {
        color: app-color('surface');
        padding: spacing('2') spacing('3');

        &.ng-option-child {
          padding-left: spacing('3');
        }
      }
      .ng-optgroup {
        padding: spacing('2');
      }

      .ng-option-marked {
        color: app-color('surface') !important;
      }
    }
  }
}
