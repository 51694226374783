@use '@angular/material' as mat;
@use 'utils' as *;

@mixin material-form {
  mat-form-field,
  mat-select .mat-select-value {
    color: currentColor;
  }

  .mat-option,
  .mat-option.mat-active {
    color: currentColor;
  }

  mat-slide-toggle:not(.no-mg) {
    width: 100%;

    .mat-slide-toggle-label {
      position: relative;

      .mat-slide-toggle-bar {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }

      .mat-slide-toggle-content {
        line-height: 1.2;
        white-space: normal;
        margin-right: 50px;
      }
    }
  }

  mat-slide-toggle {
    .mat-slide-toggle-content {
      font-size: inherit;
    }
  }

  mat-slide-toggle.mat-disabled {
    .mat-slide-toggle-label {
      color: mat.get-color-from-palette($md-disabled, 500);
    }
  }

  .no-form-field-underline {
    .mat-input-underline {
      display: none !important;
    }
  }

  mat-form-field.no-float-top,
  mat-form-field.no-material {
    .mat-form-field-infix,
    .mat-input-infix {
      border-top: 0;
    }

    &.mat-form-field-appearance-standard .mat-form-field-flex {
      padding-top: 0;
    }
  }

  mat-form-field.no-material {
    .mat-input-underline,
    .mat-form-field-underline {
      display: none;
    }

    .mat-input-wrapper,
    .mat-form-field-wrapper {
      padding-bottom: 0;
    }

    .mat-input-prefix,
    .mat-form-field-prefix {
      color: mat.get-color-from-palette($md-textgrey, 500);
    }

    &.ng-untouched,
    &.ng-pristine,
    &.empty {
      .mat-form-field-label,
      .mat-input-placeholder,
      .mat-form-field-placeholder {
        color: mat.get-color-from-palette($md-textgrey, 500);
      }
    }
  }

  mat-form-field.no-material.show-mat-error {
    .mat-input-wrapper,
    .mat-form-field-wrapper {
      padding-bottom: 0.5em;
    }
  }

  mat-form-field.no-required-asterisk {
    .mat-placeholder-required {
      display: none;
    }
  }

  mat-form-field.mg-placeholder-color {
    &.ng-untouched,
    &.ng-pristine,
    &.empty {
      .mat-form-field-label,
      .mat-input-placeholder,
      .mat-form-field-placeholder {
        color: mat.get-color-from-palette($md-textgrey, 500);
      }
    }
  }

  mat-form-field.suffix-fix {
    .mat-form-field-prefix,
    .mat-form-field-suffix {
      vertical-align: middle;
    }

    .mat-icon {
      width: 1em;
      height: 1em;
    }
  }
}
