@use 'utils' as *;

@mixin text {
  .mg-headline {
    @include mg-headline;
  }

  .mg-headline2 {
    @include mg-headline2;
  }

  .mg-headline3 {
    @include mg-headline3;
  }

  .mg-cta {
    @include mg-cta;
  }

  .mg-bodycopy {
    @include mg-bodycopy;
  }

  .mg-bodycopy-bold {
    @include mg-bodycopy-bold;
  }

  .mg-smallcopy {
    @include mg-smallcopy;
  }

  .mg-minicopy {
    @include mg-minicopy;
  }

  .mg-minitext {
    @include mg-minitext;
  }

  .mg-authortext {
    @include mg-authortext;
  }

  .mg-microtext {
    @include mg-microtext;
  }

  .mg-centered {
    text-align: center;
  }

  .mg-fullscreen {
    min-height: 100vh;
  }
}
