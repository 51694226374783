@use 'utils' as *;

// common avatar styles for profile pictures
// other specific styles like size should be defined in the component
@mixin profile-picture() {
  .profile-picture {
    height: 100%;
    border-radius: 9999px;
    background-size: cover;
  }
}
