@use 'utils' as *;

$bubbleColor1: #70b2d7;
$bubbleStart1: #4ba0cd;
$bubbleEnd1: #427fa0;
$bubbleStart2: #cd4c4a;
$bubbleEnd2: #a0120f;

@mixin bubble-btn() {
  button[mgBubbleBtn] {
    $lineHeight: 1em;
    $verticalPadding: 1em;
    $horizontalPadding: 1.5em;

    font-family: 'Muli', sans-serif;
    cursor: pointer;
    text-align: center;
    line-height: $lineHeight;
    padding: $verticalPadding $horizontalPadding;
    border-radius: #{($lineHeight + ($verticalPadding * 2)) * 0.5};
    box-shadow: 0px 3px 6px #00000029;
    outline: none;
    color: white;

    &:disabled {
      cursor: default;
      background: transparentize($bubbleColor1, 1);
      border-color: #cccccc;
      color: #cccccc;
    }

    //old button styling
    border: 1px solid $bubbleColor1;
    background: linear-gradient($bubbleColor1, #5ca8d2, #4ba0cd);

    @media (hover: none) {
      &:active {
        @include pressed-styles;
      }
    }

    @media (hover: hover) {
      &:hover:enabled {
        @include hover-styles;
      }
      &:active {
        @include pressed-styles;
      }
    }

    &.alternate-btn-one {
      background: linear-gradient($bubbleStart1, $bubbleEnd1);
      border: 1px solid $bubbleStart1;

      @media (hover: none) {
        &:active {
          @include pressed-styles-one;
        }
      }

      @media (hover: hover) {
        &:hover:enabled {
          @include hover-styles-one;
        }
        &:active {
          @include pressed-styles-one;
        }
      }
    }

    &.alternate-btn-two {
      background: linear-gradient($bubbleStart2, $bubbleEnd2);
      border: 1px solid $bubbleStart2;

      @media (hover: none) {
        &:active {
          @include pressed-styles-two;
        }
      }

      @media (hover: hover) {
        &:hover:enabled {
          @include hover-styles-two;
        }
        &:active {
          @include pressed-styles-two;
        }
      }
    }
  }
}

@mixin hover-styles {
  background: linear-gradient(#b5ddf4, #7cc9f2);
}

@mixin pressed-styles {
  background: linear-gradient(#569fc7, #3189b7);
}

@mixin hover-styles-one {
  background: linear-gradient(
    lighten($bubbleStart1, 10%),
    lighten($bubbleEnd1, 10%)
  );
}

@mixin pressed-styles-one {
  background: linear-gradient(
    darken($bubbleStart1, 10%),
    darken($bubbleEnd1, 10%)
  );
}

@mixin hover-styles-two {
  background: linear-gradient(
    lighten($bubbleStart2, 10%),
    lighten($bubbleEnd2, 10%)
  );
}

@mixin pressed-styles-two {
  background: linear-gradient(
    darken($bubbleStart2, 10%),
    darken($bubbleEnd2, 10%)
  );
}
