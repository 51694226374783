@use '@angular/material' as mat;
@use 'utils' as *;

@mixin pill-checkbox($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  mg-pill-checkbox {
    .mg-pill-checkbox {
      border-color: app-color('primary');
    }

    &:focus .mg-pill-checkbox {
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.16);
    }

    &.mg-pill-checkmark-checked {
      color: mat.get-contrast-color-from-palette($primary, 500);

      .mg-pill-checkbox {
        background-color: app-color('primary');
      }
    }

    &.mg-pill-checkmark-unchecked {
      color: app-color('primary');

      .mg-pill-checkbox {
        background-color: transparentize(
          map-deep-get($app-theme, 'color', 'primary'),
          1
        );
      }
    }
  }

  mg-pill-checkbox.alternate {
    .mg-pill-checkbox {
      border-color: mat.get-color-from-palette($accent);
    }

    &:focus .mg-pill-checkbox {
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.16);
    }

    &.mg-pill-checkmark-checked {
      color: mat.get-contrast-color-from-palette($accent, 500);

      .mg-pill-checkbox {
        background-color: mat.get-color-from-palette($accent);
      }
    }

    &.mg-pill-checkmark-unchecked {
      color: $greyTextColor;

      .mg-pill-checkbox {
        background-color: transparentize(mat.get-color-from-palette($accent), 1);
      }
    }
  }
}
