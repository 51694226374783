@use 'utils' as *;

@mixin helper-utils {
  @each $option, $val in map-get($app-theme, 'spacing') {
    $value-as-rem-unit: rem-calc($val);
    .mg-padding-#{$option} {
      padding: rem-calc($value-as-rem-unit);
    }
    .mg-px-#{$option} {
      padding-inline: rem-calc($value-as-rem-unit);
    }
    .mg-py-#{$option} {
      padding-block: rem-calc($value-as-rem-unit);
    }
  }

  @each $option, $val in map-get($app-theme, 'spacing') {
    $value-as-rem-unit: rem-calc($val);
    .mg-margin-#{$option} {
      margin: rem-calc($value-as-rem-unit);
    }
    .mg-mx-#{$option} {
      margin-inline: rem-calc($value-as-rem-unit);
    }
    .mg-my-#{$option} {
      margin-block: rem-calc($value-as-rem-unit);
    }
  }
}
