@use '@angular/material' as mat;
@use 'utils' as *;

@mixin __mg-navicon-color($color) {
  color: mat.get-color-from-palette($color);

  &.active {
    color: mat.get-color-from-palette($color, A700);
  }
}

@mixin navigation {
  mat-list-item,
  .mg-subnav-mat-menu-item {
    &.minga-designer {
      &.tablet,
      &.mobile {
        [mg-nav-item-image] {
          height: 40px;
        }
      }

      [mg-nav-item-image] {
        height: 22px;
        // for better minga-studio-logo vertical alignment with icon
        margin-bottom: 3px;
      }
    }

    &.gradient {
      mg-button-icon,
      mg-button,
      button.mg-button,
      mg-navicon,
      .mat-icon {
        color: inherit !important;
      }

      border-radius: 3px;
      border: none !important;
    }

    &.gradient + & {
      border: none !important;
    }

    // color icons like other navs
    &:first-child,
    &:nth-child(6n),
    &.index-0,
    &:nth-child(11n) {
      mg-navicon {
        color: $mingaPink !important;
      }
    }

    &:nth-child(12n-10),
    &:nth-child(17n - 10),
    &:nth-child(12n) {
      mg-navicon {
        color: $mingaNavy !important;
      }
    }

    &:nth-child(13n - 10),
    &:nth-child(8n),
    &:nth-child(13n) {
      mg-navicon {
        color: $mingaGreen !important;
      }
    }

    &:nth-child(14n-10),
    &:nth-child(9n),
    &:nth-child(14n) {
      mg-navicon {
        color: $mingaYellow !important;
      }
    }

    &:nth-child(5n) {
      mg-navicon {
        color: $mingaPurple !important;
      }
    }
  }

  .nav-item-badge {
    position: absolute;
    background-color: #de1717;
    width: 7px;
    height: 7px;
    border: 1px solid white;
    border-radius: 50%;
    bottom: 13px;
    left: 17px;
    z-index: 1;

    &.xs {
      bottom: 35%;
      left: 55%;
    }

    &.topright {
      bottom: unset;
      left: unset;
      top: 20%;
      right: 20%;
    }
  }

  mg-navbar .nav-item-inner .mat-list-item .mat-list-item-content {
    padding: 0 8px;
  }

  .mg-subnav-mat-menu-item {
    line-height: 0 !important;

    .mg-subnav-mat-menu-item-container {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      height: 100%;

      > span {
        display: inline-block;
      }
    }
  }
}

@mixin navicon($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  mg-navicon {
    &[color='primary'] {
      @include __mg-navicon-color($primary);
    }

    &[color='accent'] {
      @include __mg-navicon-color($accent);
    }
  }
}

@mixin navbar($theme, $borderColor, $textBlack: $md-cardgrey) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  $primaryBg: mat.get-color-from-palette($primary, darker-contrast);
  $accentBg: mat.get-color-from-palette($accent, 50);

  $textBlackColor: mat.get-color-from-palette($textBlack, 500);

  mg-navbar {
    background-color: $primaryBg;
    border-top: 0.5px solid $borderColor;
    border-bottom: 0.5px solid $borderColor;

    &[color='primary'] {
      background-color: $primaryBg;

      // No color is defined, so let's inherit the navbars!
      mg-navicon:not([color]) {
        @include __mg-navicon-color($primary);
      }
    }

    // for legacy as pre angular 6 didn't have overflow: hidden...
    .mat-expansion-panel {
      overflow: visible;
    }

    &[color='accent'] {
      background-color: $accentBg;

      // No color is defined, so let's inherit the navbars!
      mg-navicon:not([color]) {
        @include __mg-navicon-color($accent);
      }
    }
  }
}

@mixin __mg-nav-item-color($color) {
  color: mat.get-color-from-palette($color);

  &:hover {
    color: mat.get-color-from-palette($color, 300);

    mg-navicon {
      color: mat.get-color-from-palette($color, 300);
    }

    // leave sub-nav items at original coloring
    .mat-list-item {
      color: mat.get-color-from-palette($color, 500);
    }
  }
}

@mixin navitem($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  [mg-nav-item] {
    @include __mg-nav-item-color($primary);

    &[color='primary'] {
      @include __mg-nav-item-color($primary);
    }

    &[color='accent'] {
      @include __mg-nav-item-color($accent);
    }
  }

  [mg-nav-item-exp-panel] {
    background-color: transparent;

    .mat-expansion-panel-header {
      padding-left: 0;
    }
    .mat-expansion-panel-body {
      --mg-mat-expansion-panel-body-padding: 0;

      .mat-list {
        padding-top: 0;

        // @TODO: Review for deletion.
        .mat-list-item {
          color: mat.get-color-from-palette($primary, 500);
          border-top: 1px solid mat.get-color-from-palette($md-disabled, 600);
          height: 40px;

          &:last-child {
            border-bottom: 1px solid $bodyTextColor;
          }

          .sub-navicon {
            mat-icon {
              height: 20px;
              width: 20px;
            }
          }
        }
      }
    }
  }

  //@TODO: Legacy layout, review for deletion.
  .nav-mat-menu.mat-menu-panel {
    background-color: mat.get-color-from-palette($md-disabled, 100);
    max-width: 100vw;
    width: 100vw;
    border-top: 1px solid mat.get-color-from-palette($md-disabled, 300);
    display: none;

    &.nav-mat-menu-fixed {
      position: fixed;
      left: 0;
      right: 0;
    }

    .mat-menu-content {
      padding-top: 0;
      padding-bottom: 0;
    }

    .mat-menu-item {
      border-bottom: 1px solid mat.get-color-from-palette($md-disabled, 300);

      &:last-child {
        border-bottom: none;
      }
    }
    .mat-menu-item,
    .mat-menu-item mg-navicon:not([color]) {
      color: mat.get-color-from-palette($md-navyblue, 500);
    }
  }
  @media (max-width: $fxLayout_sm) {
    .nav-mat-menu.mat-menu-panel:not(.disabled) {
      display: block;
    }
  }
}
