@use 'utils' as *;

/**
 * These are material UI style overrides for drop down menus and elements of
 * drop downs, such as mat-menu, mat-menu-content and mat-menu-items.
 * 
 * @TODO:
 * This css should be encapsulated in a MenuDropDown component, to
 * isolate the use of <mat-menu> in the app.
 */
@mixin material-menu {
  .mat-menu-panel {
    min-height: auto !important;
  }

  .mat-menu-content {
    padding: 0 !important;
  }

  /**
   * Not all mat-menu-item children have the font colour set, so we are setting
   * it here as an extra precaution. If at some point we decide to use
   * different menu item text colour, then this should be removed so the
   * children don't have to do silly CSS rules to override it.
   */
  .mat-menu-content .mat-menu-item {
    color: rgba(0, 51, 102, 1);
    padding: spacing('2') spacing('3');

    // Let the content determine the item height.
    height: auto !important;
    line-height: inherit;

    &:hover {
      background-color: rgba(53, 138, 181, 0.08) !important;
    }
    &:active {
      background-color: rgba(53, 138, 181, 0.12) !important;
    }

    &.mat-menu-item-submenu-trigger {
      padding-right: spacing('8') !important;
    }
  }
}
