@use 'utils' as *;

@mixin tooltip() {
  .mg-tooltip {
    background-color: white !important;
    margin: 0;
    border-radius: radius(small);
    border: 1px solid map-get($grey, '100');
    padding: spacing('1') spacing('2');
    font-weight: 400;
    font-style: normal;
    font-size: rem-calc(10);
    color: app-color(surface) !important;
    line-height: 1;
    transform: translateY(-10px) translateX(0px) !important;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15),
      0px 2px 2px 0px rgba(0, 0, 0, 0.2);
  }

  // We need this to fix a bug with material tooltip breaking scrolling(janky, unresponsive) on mobile, eg report tables
  // https://github.com/angular/components/issues/25566
  .mat-tooltip-trigger,
  // this class below will future proofs us for newer Material version
  .mat-mdc-tooltip-trigger {
    touch-action: auto !important;
    -webkit-user-drag: auto !important;
  }
}
