// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// !! DEPRECATED - DON'T REFERENCE ANY VARIABLES FROM THIS FILE ANYMORE !!
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

$mingaBlue1: #003366;
$mingaYellow1: #ffcc00;
$mingaGreen1: #1dce4d;
$mingaGrey1: #f5f5f5;
$mingaGrey2: #cccccc;
$linkColor: #9933ff;
$bodyTextColor: #737373;
$disabledColor: #737373;
$greyTextColor: #888888;

// Taken from card vars. Probably should be re-named.
$dateColor: #737373;
$pinIconColor: #737373;
$bodyColor: #000;
$displayNameColor: #003366;
$actionsColor: #003366;
$moreTextColor: #9933ff;
$moreImageBgColor: #33ccff;
$borderRadius: 8px;
$errorColor: #de1717;
$textBlack: #2f2f2f;
$resolvedColor: $mingaGreen1;
$borderColor: #ccc;
$borderLightColor: #e2e2e2;
$borderColor2: #d0d0d0;
$borderColor3: #dedede;
$borderColorDark: #707070;
$policyLinkColor: #4ba0cd;
$leaderboardPurple: #745b9f;
$leaderboardLightPurple: #a090d8;
$settingsMainSectionColor: #243b75;
$pastEventTextColor: #7b6dc5;
$ticketTextColor: #958747;
$infoTextColor: #aaaaaa;
$eventLightGreen: #86bfac;
$backgroundDimmedColor: #e4e4e4;
$lightBlueFieldBorder: #609fc9;

$mingaColor1: #003366;
$mingaColor2: #9933ff;
$mingaColor3: #ffcc00;
$mingaColor4: #33ccff;

$mingaBlue2: #70b2d7;
$mingaBlue3: #d7edf8;
$mingaBlue4: #5ab3db;

// Draft clean/dirty colors
$bodyDirtyColor: $textBlack;
$bodyCleanColor: $bodyTextColor;
$titleInputDirtyColor: $mingaBlue1;
$titleInputCleanColor: $bodyTextColor;

$mingaColor3Light: #fef8df;

$mingaPink: #c66b83;
$mingaNavy: #1c2f59;
$mingaGreen: #4ba083;
$mingaYellow: #d8b300;
$mingaPurple: #7a57a4;

$uiActiveBgGrey: #ececec;
$mingaBackgroundLightGrey: #f4f4f4;
$textLightGray: #b2b2b2;

$pointsYellow: #ebd270;

$eventsRed: #e2572d;
$eventsCheckInBlue: #4b9ecb;
$eventsCheckOutRed: #e1524c;

$studentIdYellow: #e1c764;
$studentIdDeactivateRed: #ce6560;
$studentIdTeal: #57a49e;

$idPrimary: #85649e;
$idAccent: #78efd9;

$hallpassPrimary: #e67aa0;
$hallpassAccent: #d6e261;

$communityPrimary: #e2ab56;
$communityAccent: #1d47b9;

$pbisPrimary: #91bcad;
$pbisAccent: #db6578;
$pbisTertiary: #56a599;

$checkinPrimary: #1d9fb9;
$checkinAccent: #daac64;

$peoplePrimary: #4ba0cb;
$peopleAccent: #003366;

$mmPrimary: #2b55c3;
$mmAccent: #6fef99;

$flexPrimary: #21a28e;
$flexAccent: #bde281;

$offlineOrange: #b75130;
