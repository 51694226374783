@use '@angular/material' as mat;
@use 'utils' as *;

@mixin overlay($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  $primaryBg: mat.get-color-from-palette($primary, darker-contrast);
  $accentBg: mat.get-color-from-palette($accent, 50);

  .success-overlay {
    height: 100%;
    text-align: center;
    position: relative;

    .checkmark-wrap {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
    }
  }

  .overlay-wrap {
    color: mat.get-color-from-palette($primary, 700);
  }

  mg-overlay .overlay-nav > * {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  mg-overlay:not(.mg-overlay-wide)
    .overlay-wrap
    .overlay-nav:not(.sticky)
    mg-overlay-nav-item {
    @media (min-width: $overlayMaxWidth + ($overlayMaxWidthMargin * 2)) {
      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }

  mg-overlay.mg-overlay-wide
    .overlay-wrap:not(.has-split-content)
    .overlay-nav:not(.sticky)
    mg-overlay-nav-item {
    @media (min-width: $overlayWideMaxWidth + ($overlayMaxWidthMargin * 2)) {
      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }

  mg-overlay.mg-overlay-wide.has-split-content {
    @media (max-width: $overlayMaxWidth) {
      .mg-overlay-split-content-small-hidden {
        display: none !important;
      }
    }
  }

  mg-overlay .overlay-nav-bottom {
    transform: translateY(0%);
    transition: 300ms ease transform;
  }

  body.keyboard-shown {
    mg-overlay .overlay-nav-bottom {
      transform: translateY(100%);
    }
  }
}

@mixin primary-modal-overlay {
  .primary-overlay-backdrop {
    @each $name, $color in $overlay-backdrop-options {
      &-#{$name} {
        background-color: $color;
      }
    }
  }
}
