@use '@angular/material' as mat;
@use 'utils' as *;

@mixin -app-color-vars {
  @each $color, $value in map-get($app-theme, 'color') {
    --mg-color-#{"" + $color}: #{$value};
  }

  @each $key, $color in $primary {
    --mg-color-primary-#{"" + $key}: #{$color};
  }

  @each $key, $color in $secondary {
    --mg-color-secondary-#{"" + $key}: #{$color};
  }

  @each $key, $color in $tertiary {
    --mg-color-tertiary-#{"" + $key}: #{$color};
  }

  @each $key, $color in $error {
    --mg-color-error-#{"" + $key}: #{$color};
  }

  @each $key, $color in $warning {
    --mg-color-warning-#{"" + $key}: #{$color};
  }

  @each $key, $color in $success {
    --mg-color-success-#{"" + $key}: #{$color};
  }

  @each $key, $color in $grey {
    --mg-color-grey-#{"" + $key}: #{$color};
  }
}

@mixin global {
  :root {
    @include -app-color-vars;
  }

  html.cdk-global-scrollblock {
    overflow-y: initial !important;
  }

  .cdk-global-overlay-wrapper {
    display: flex;
    position: absolute;
  }

  html {
    box-sizing: border-box;
    font-size: $global-font-size;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  body {
    padding: 0;
    margin: 0;
    background-color: #fff;
    position: relative;
    font-family: 'Muli', sans-serif;
    line-height: 1.35;
    font-size: $global-font-size;
    color: app-color('surface');
    --mg-background-color: #f5f5f5;
    -webkit-overflow-scrolling: touch;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0.02);
    overscroll-behavior: contain;
    background-size: cover;
    background-position: center;
    &.no-scrolling-splash,
    &.no-overflow {
      overflow: hidden;
    }
    &.restore-scroll {
      user-select: none;
      pointer-events: none;
      touch-action: none;
    }
    .logo {
      background-image: url(/assets/logo/MingaLogo_New.png);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
    @media print {
      #userpilot-resource-centre-frame {
        display: none !important;
      }
    }

    // removes macs scroll bounce on end, which didn't play nice with fixed items
    @include breakpoint(medium) {
      overscroll-behavior: none;
    }
  }

  body.mg-layout-silh-landing > .body-bg0 {
    background-image: url(/assets/landing/bg-desktop.png);

    @media (max-width: 599px) {
      background-image: url(/assets/landing/bg-mobile.png);
    }
  }

  body.mg-layout-face-bg > .body-bg0 {
    background-image: url(/assets/landing/bg-desktop.png);

    @media (max-width: 599px) {
      background-image: url(/assets/landing/bg-mobile.png);
    }
  }

  body > div:not([class]) {
    display: none;
  }

  body > .body-bg {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-position: center;
    pointer-events: none;
    user-select: none;

    &.body-bg0 {
      z-index: 0;
    }

    &.body-bg1 {
      z-index: 1;
    }
  }

  mg-icon[block] {
    display: block;
    mat-icon {
      display: block;
      svg {
        display: block;
      }
    }
  }

  form {
    font-size: 13px;
  }

  mark {
    &.mg-mark-yellow {
      background-color: $mingaYellow1;
    }
  }

  // storybook
  body > #root,
  body > #docs-root {
    display: block;
  }

  // user flow
  #userflow-ui,
  #viewedit-app {
    display: block;
  }

  .mg-swipe-container-item-wrap {
    &.mg-swipe-container-item-align-middle {
      margin-top: auto;
      margin-bottom: auto;
    }

    &.mg-swipe-container-item-align-top {
      margin-bottom: auto;
    }

    &.mg-swipe-container-item-align-bottom {
      margin-top: auto;
    }
  }

  .color-picker {
    border-radius: 6px;
    border: 1px solid lightgrey;
    overflow: hidden;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.17);
    .selected-color {
      border-radius: 50%;
      border: 1px solid white !important;
      border-radius: 50%;
      box-shadow: 0 0 3px rgba(0, 0, 0, 1), 0 0 10px rgba(0, 0, 0, 0.5) inset;
    }
    input {
      border-radius: 6px;
      border: 1px solid lightgrey;
      color: grey;
      &:focus {
        outline: none;
      }
    }
  }

  .ps-dialog-backdrop {
    background: rgba(0, 51, 102, 0.7);
  }

  .ps-dialog-panel {
    @include breakpoint(xsmall only) {
      height: auto !important;
    }
    & > .mat-dialog-container {
      padding: 0;
      border-radius: 20px;
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3),
        0px 4px 8px 3px rgba(0, 0, 0, 0.15);
    }
  }

  mg-mention {
    display: inline-block;
    cursor: pointer;
    text-decoration: none;
    white-space: normal;

    background-color: transparentize(#9933ff, 0.95);

    &:hover {
      background-color: transparentize(#9933ff, 0.85);
    }
  }

  .mg-mentionable-mention-wrap {
    user-select: none;
    mg-mention {
      word-spacing: 0.2em;
    }
  }

  .mg-hidden {
    opacity: 0;
    user-select: none;
    pointer-events: none;
  }

  .mg-hidden-no-flow {
    opacity: 0;
    user-select: none;
    pointer-events: none;
    position: absolute;
  }

  .minga-grey-theme {
    @include mat.checkbox-theme($minga-app-grey-theme);
  }

  .mat-drawer-container.mg-sidenav-container {
    .mat-drawer-backdrop {
      position: fixed;
    }

    &:not(.fixed) .mg-sidenav {
      margin-left: $feedItemsMobileMaxWidth + $navLeftWidth;

      .mat-drawer-inner-container {
        padding-left: $sideToolbarPaddingRight;
        box-sizing: border-box;
      }
    }
  }
}
