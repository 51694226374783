@use "sass:math";

@use 'utils' as *;

// The base stylings for mg-form-field. This should only be included once.
@mixin form-field() {
  $margin: 16px;
  $padding: 8px;
  $lineHeight: 14px;
  $floatLabelFontSize: 12px;
  $extraHintMargin: 4px;
  $pristineInputBgColor: #f5f5f5;
  $autoFillBgColor: rgb(232, 240, 254);
  $inputBgColor: rgba(255, 255, 255, 0);
  $inputColor: #737373;
  $shadowColor: #33ccff;
  $disabledInputColor: #cccccc;
  $fixedHeightUnit: 12px;
  $defaultFormFieldHeight: 48px;

  .mg-form-field {
    // ensure corect width for  mg-form-field elements with padding in forms
    // and overlays
    box-sizing: border-box;

    mat-label {
      color: $inputColor;
      color: inherit;
      font-weight: bold;
    }

    &.flex-input {
      .mat-form-field-infix {
        display: flex;
      }
    }

    &:not(.mg-no-optional-suffix) {
      mat-label::after {
        content: ' (optional)';
        font-weight: 400;
      }
    }

    *[required]:not([required*='false']) + .mat-form-field-label-wrapper,
    mat-select.mat-select-required + .mat-form-field-label-wrapper {
      mat-label {
        &::after {
          content: '';
        }
      }
    }

    &.mg-readonly {
      pointer-events: none;
      color: $disabledInputColor;

      .mat-select-arrow {
        color: transparent;
      }

      .mat-form-field-wrapper
        .mat-form-field-flex
        .mat-form-field-outline
        .mat-form-field-outline-start,
      .mat-form-field-wrapper
        .mat-form-field-flex
        .mat-form-field-outline
        .mat-form-field-outline-gap,
      .mat-form-field-wrapper
        .mat-form-field-flex
        .mat-form-field-outline
        .mat-form-field-outline-end {
        border-color: transparent;
      }
    }

    // ensure if set to not show we definitely don't show the asterisk!
    &.mg-hide-required-marker {
      .mat-form-field-required-marker {
        display: none;
      }
    }

    &.mg-no-visible-input {
      input {
        display: none;
      }
    }

    &.mg-no-input-margin,
    &.mg-fixed-height.mat-form-field-appearance-outline.mg-no-input-margin {
      margin-bottom: 0 !important;
    }

    &.mat-form-field-appearance-legacy.mg-no-input-margin {
      .mat-form-field-wrapper {
        padding-bottom: 0;
      }

      .mat-form-field-underline {
        display: none;
      }
    }

    .mat-form-field-disabled {
      color: $disabledInputColor;
    }

    &.mat-form-field-appearance-outline {
      margin-bottom: calc(var(--hint-height, 14px) + 16px);

      // fix vertical alignment of mat-chip lists
      &.mat-form-field-type-mat-chip-list {
        mat-chip-list:not(.single-line) {
          div.mat-chip-list-wrapper {
            margin-bottom: 2px;
          }

          .mat-chip-input: {
            margin-top: 10px;
            margin-bottom: 10px;
          }
        }
      }

      &.mat-form-field-disabled.mg-has-value {
        .mat-form-field-outline {
          .mat-form-field-outline-start,
          .mat-form-field-outline-gap,
          .mat-form-field-outline-end {
            border-width: 1px;
            background-color: rgba(255, 255, 255, 0);
          }
        }
      }

      .mat-select-arrow-wrapper {
        transform: translateY(0%); // want it centered, not the default -25%
      }

      .mat-form-field-wrapper {
        padding-bottom: 0;
        margin: 0;
        line-height: 1.2;
      }

      .mat-form-field-label-wrapper {
        transition: font-size 0.1s cubic-bezier(0.25, 0.8, 0.25, 1);
        overflow: visible;
      }

      .mat-form-field-label {
        line-height: 1.25;
      }

      &.mat-form-field-should-float {
        .mat-form-field-label {
          transform: translateY(-1.9em) scale(0.75);
        }

        mat-label {
          font-weight: normal;
        }
      }

      .mat-form-field-outline {
        top: #{math.div($fixedHeightUnit, 3)};
      }

      .mat-form-field-flex {
        padding: 0 $margin 0 $margin;
        margin-top: -#{$fixedHeightUnit * 0.25};
        align-items: center;
      }

      .mat-form-field-subscript-wrapper {
        padding: 0 $fixedHeightUnit;
        margin-top: 0;
        padding-top: 2px;
        top: 100%;
        width: var(--mg-form-input-subscript-wrapper-width, 100%);
      }

      .mat-form-field-prefix,
      .mat-form-field-suffix {
        position: static;
        z-index: 1;
        border-top: #{math.div($fixedHeightUnit, 3)} solid transparent;
        display: flex;
        align-items: center;
        line-height: 1;

        > div[matsuffix] {
          display: flex;
          align-items: center;
          height: 100%;
        }

        > .mat-icon,
        > mg-icon,
        > div[matstuffix] > .mat-icon,
        > div[matsuffix] > mg-icon {
          line-height: 1;
          display: inline-block !important;
        }

        > .mat-icon.material-icons,
        > div[matsuffix] > .mat-icon.material-icons {
          font-size: 165%;
          line-height: var(--mg-icon-size, 16px);
          height: auto;
          width: auto;
          display: inline-block !important;
        }

        > mg-icon,
        > div[matsuffix] > mg-icon {
          height: var(--mg-icon-size, 16px);
          width: var(--mg-icon-size, 16px);
        }

        > div[matsuffix] > mg-icon {
          height: 100%;
        }
      }

      .mat-form-field-prefix mg-icon .mat-icon,
      .mat-form-field-suffix mg-icon .mat-icon {
        font-size: $fixedHeightUnit;
      }

      &:not(.mat-form-field-invalid) {
        .mat-form-field-outline.mat-form-field-outline-thick {
          color: var(--mg-form-input-hover-color, $shadowColor);
        }
      }
    }

    // default to --mg-form-field-height height for consistent form inputs
    &.mat-form-field-appearance-outline:not(.mg-responsive-height) {
      .mat-form-field-label-wrapper {
        padding-top: #{$fixedHeightUnit * 0.9};
        line-height: 1;
        top: -#{$fixedHeightUnit * 0.9};
      }

      &:not(.mat-form-field-should-float) .mat-form-field-label {
        top: calc(var(--mg-form-field-height, #{$defaultFormFieldHeight}) / 2);
        transform: translateY(-0.25em);
      }

      .mat-form-field-wrapper {
        min-height: calc(
          var(--mg-form-field-height, #{$defaultFormFieldHeight}) + #{math.div($fixedHeightUnit, 3)}
        );
      }

      .mat-form-field-flex {
        min-height: calc(
          var(--mg-form-field-height, #{$defaultFormFieldHeight}) + #{math.div($fixedHeightUnit, 3)}
        );
      }

      .mat-form-field-infix {
        border-top: #{$fixedHeightUnit * 0.9} solid transparent;

        padding: calc(
            (var(--mg-form-field-height, #{$defaultFormFieldHeight}) / 2) - 1em
          )
          0 calc((var(--mg-form-field-height, #{$defaultFormFieldHeight}) / 4))
          0;
        padding: 0;
        top: 0;

        > *:first-child:not(.mat-form-field-label-wrapper):not(mat-chip-list) {
          min-height: calc(
            var(--mg-form-field-height, #{$defaultFormFieldHeight}) - #{$fixedHeightUnit *
              0.9}
          ) !important;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          position: relative;
          top: -#{$fixedHeightUnit * 0.25};
          margin-top: 0;

          > * {
            width: 100%;
          }
        }

        > textarea:first-child.mat-input-element {
          min-height: calc(
            var(--mg-form-field-height, #{$defaultFormFieldHeight}) -
              (#{$fixedHeightUnit * 0.9} * 2)
          ) !important;
          padding-top: #{$fixedHeightUnit * 0.9};
        }
      }

      &.mat-form-field-should-float {
        textarea.mat-input-element
          + .mat-form-field-label-wrapper
          .mat-form-field-label {
          // offset by 2px from material textarea.mat-input-element padding
          transform: translateY(calc(-1.9em + 2px)) scale(0.75);
        }
      }

      .mat-form-field-prefix,
      .mat-form-field-suffix {
        height: calc(
          var(--mg-form-field-height, #{$defaultFormFieldHeight}) - #{math.div($fixedHeightUnit, 3)}
        );
      }
    }

    .mat-input-element {
      caret-color: currentColor;
    }

    &.mat-form-field-appearance-outline.mg-responsive-height {
      .mat-form-field-wrapper {
        height: inherit;

        .mat-form-field-flex {
          height: inherit;
        }
      }
    }

    // Always show the hint
    .mat-hint {
      transform: translateY(0);
    }

    .mat-form-field-label {
      color: $inputColor;
    }

    .mat-form-field-outline {
      color: var(--mg-form-input-outline-color, rgba(0, 0, 0, 0.12));

      .mat-form-field-outline-start {
        border-radius: var(--mg-form-input-border-radius, 5px) 0 0
          var(--mg-form-input-border-radius, 5px);
      }

      .mat-form-field-outline-end {
        border-radius: 0 var(--mg-form-input-border-radius, 5px)
          var(--mg-form-input-border-radius, 5px) 0;
      }

      .mat-form-field-outline-start,
      .mat-form-field-outline-gap,
      .mat-form-field-outline-end {
        border-width: var(--mg-form-input-border-width, 0px);
        border-color: var(--mg-form-input-border-color, currentColor);
        background-color: var(
          --mg-form-input-background-color,
          $pristineInputBgColor
        );
      }

      &.mat-form-field-outline-thick {
        .mat-form-field-outline-start,
        .mat-form-field-outline-gap,
        .mat-form-field-outline-end {
          border-width: 1px;
        }
      }
    }

    &:not(.mat-form-field-disabled) {
      .mat-form-field-flex:hover,
      &.mat-focused,
      &.mat-form-field-should-float {
        .mat-form-field-outline {
          .mat-form-field-outline-start,
          .mat-form-field-outline-gap,
          .mat-form-field-outline-end {
            border-width: 1px;
            background-color: $inputBgColor;
          }
        }
      }
    }

    &.mat-form-field-autofilled {
      .mat-form-field-outline {
        .mat-form-field-outline-start,
        .mat-form-field-outline-gap,
        .mat-form-field-outline-end {
          background-color: $autoFillBgColor !important;
        }
      }
    }
  }
}

@mixin form-field-theme($theme, $pristineBackground: transparent) {
  // @TODO: Fill in color theme
}
