@use '../settings' as *;
@use 'sizes' as *;
@use 'responsive' as *;

@mixin mg-cta($multiplier: 1) {
  font-family: 'Muli', sans-serif;
  line-height: 1;
  @include responsiveScaling(
    30px * $multiplier,
    35px * $multiplier,
    font-size,
    $iphone6plusWidth
  );
}

@mixin mg-headline($multiplier: 1) {
  font-family: 'Muli', sans-serif;
  font-weight: 700;
  line-height: 1;
  @include responsiveScaling(
    21px * $multiplier,
    22px * $multiplier,
    font-size,
    $iphone6plusWidth
  );
}

@mixin mg-headline2($multiplier: 1) {
  font-family: 'Muli', sans-serif;
  font-weight: 700;
  line-height: 1;
  @include responsiveScaling(
    18px * $multiplier,
    19px * $multiplier,
    font-size,
    $iphone6plusWidth
  );
}

@mixin mg-headline3($multiplier: 1) {
  font-family: 'Muli', sans-serif;
  font-weight: 700;
  line-height: 1;
  @include responsiveScaling(
    16px * $multiplier,
    17px * $multiplier,
    font-size,
    $iphone6plusWidth
  );
}

@mixin mg-headline4($multiplier: 1) {
  font-family: 'Muli', sans-serif;
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;
  @include responsiveScaling(
    14px * $multiplier,
    15px * $multiplier,
    font-size,
    $iphone6plusWidth
  );
}

@mixin mg-headline5($multiplier: 1) {
  font-family: 'Muli', sans-serif;
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;
  @include responsiveScaling(
    14px * $multiplier,
    15px * $multiplier,
    font-size,
    $iphone6plusWidth
  );
}

@mixin mg-headline6($multiplier: 1) {
  font-family: 'Muli', sans-serif;
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;
  @include responsiveScaling(
    14px * $multiplier,
    15px * $multiplier,
    font-size,
    $iphone6plusWidth
  );
}

@mixin mg-bodycopy($multiplier: 1) {
  font-family: 'Muli', sans-serif;
  line-height: 1.35;
  @include responsiveScaling(
    16px * $multiplier,
    17px * $multiplier,
    font-size,
    $iphone6plusWidth
  );
}

@mixin mg-bodycopy-bold($multiplier: 1) {
  font-family: 'Muli', sans-serif;
  font-weight: 700;
  line-height: 1.35;
  @include responsiveScaling(
    16px * $multiplier,
    17px * $multiplier,
    font-size,
    $iphone6plusWidth
  );
}

@mixin mg-smallcopy($multiplier: 1) {
  font-family: 'Muli', sans-serif;
  line-height: 1.35;
  @include responsiveScaling(
    13px * $multiplier,
    14px * $multiplier,
    font-size,
    $iphone6plusWidth
  );
}

@mixin mg-actioncopy($multiplier: 1) {
  font-family: 'Muli', sans-serif;
  line-height: 1.35;
  @include responsiveScaling(
    12px * $multiplier,
    13px * $multiplier,
    font-size,
    $iphone6plusWidth
  );
}

@mixin mg-minicopy($multiplier: 1) {
  font-family: 'Muli', sans-serif;
  line-height: 1.35;
  @include responsiveScaling(
    12px * $multiplier,
    13px * $multiplier,
    font-size,
    $iphone6plusWidth
  );
}

@mixin mg-authortext($multiplier: 1) {
  font-family: 'Muli', sans-serif;
  line-height: 1.35;
  @include responsiveScaling(
    16px * $multiplier,
    17px * $multiplier,
    font-size,
    $iphone6plusWidth
  );
}

@mixin mg-minitext($multiplier: 1) {
  font-family: 'Muli', sans-serif;
  line-height: 1;
  @include responsiveScaling(
    9px * $multiplier,
    10px * $multiplier,
    font-size,
    $iphone6plusWidth
  );
}

@mixin mg-microtext($multiplier: 1) {
  font-family: 'Muli', sans-serif;
  line-height: 1;
  font-size: 9px * $multiplier;
}
