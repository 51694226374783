@use 'utils' as *;

@mixin material-checkbox {
  .mat-checkbox[mg-color='white'] {
    .mat-checkbox-frame {
      border-color: white;
    }

    &.mat-checkbox-checked {
      .mat-checkbox-frame {
        border-color: transparent;
      }
    }
  }

  .mat-checkbox-background {
    border-radius: 4px !important;
  }

  .mat-checkbox.mg-responsive {
    .mat-checkbox-inner-container {
      --mat-checkbox-box-size: 1em;
    }
  }

  .mat-checkbox.mg-align-center {
    .mat-checkbox-layout {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .mat-checkbox-label {
      flex: 1;
      line-height: 0;
    }
  }

  .mat-checkbox .mat-checkbox-inner-container {
    height: var(--mat-checkbox-box-size, 20px);
    width: var(--mat-checkbox-box-size, 20px);
  }
}
