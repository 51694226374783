@use 'units' as *;

@mixin splash-screen-bg-layers {
  > .bg-layer {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: 100% 100%;
  }

  > .bg-layer1 {
    z-index: 1;
    background: linear-gradient(#24a0d3, #003366 73%);
  }

  > .bg-layer2 {
    z-index: 2;
    background-image: url(/assets/splashscreen/Right-Bottom@1x.png);

    @media (min-width: 600px) {
      background-image: url(/assets/splashscreen/Right-Bottom@2x.png);
    }
  }

  > .bg-layer3 {
    z-index: 3;
    background-image: url(/assets/splashscreen/Left-Bottom@1x.png);
    @media (min-width: 600px) {
      background-image: url(/assets/splashscreen/Left-Bottom@2x.png);
    }
  }

  > .bg-layer4 {
    z-index: 4;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: bottom left;
    background-image: url(/assets/landing/mobile-minga-landing-bg.png);

    @media (min-width: 600px) {
      background-image: url(/assets/landing/desktop-minga-landing-bg.png);
    }
  }
}

@keyframes splashscreen-leave {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes splashscreen-elements-left {
  0% {
    left: -100%;
  }

  100% {
    left: 100%;
  }
}

@keyframes splashscreen-elements-right {
  0% {
    left: 100%;
  }

  100% {
    left: -100%;
  }
}

@mixin generic-mobile {
  @media (max-width: 599px) {
    @content;
  }
}

@mixin generic-desktop {
  @media (min-width: 600px) {
    @content;
  }
}

@mixin generic-desktop-wide {
  @media (min-width: 1024px) {
    @content;
  }
}

@mixin ios-2x-universal-anyany {
  @media (min-width: 768px) and (min-height: 768px) and (-webkit-device-pixel-ratio: 2) {
    @content;
  }
}

@mixin ios-2x-universal-comany {
  @media (max-width: 375px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait) {
    @content;
  }
}

@mixin ios-2x-universal-comcom {
  @media (max-width: 375px) and (max-height: 375px) and (-webkit-device-pixel-ratio: 2) {
    @content;
  }
}

@mixin ios-3x-universal-anyany {
  @media (min-width: 768px) and (min-height: 768px) and (-webkit-device-pixel-ratio: 3) {
    @content;
  }
}

@mixin ios-3x-universal-comany {
  @media (max-width: 414px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
    @content;
  }
}

@mixin ios-3x-universal-comcom {
  @media (max-width: 375px) and (max-height: 375px) and (-webkit-device-pixel-ratio: 3) {
    @content;
  }
}
