@use 'utils' as *;

@mixin splashscreen {
  #splashscreen {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2000;
    text-align: center;

    background-position: center;
    background-size: cover;

    .logo {
      @keyframes small-to-bigger {
        0% {
          height: 8%;
        }

        50% {
          height: 10%;
        }

        100% {
          height: 8%;
        }
      }
      animation: small-to-bigger 5s linear infinite;
      background-image: url(/assets/icon/splash-m.svg);
      background-repeat: no-repeat;
      background-size: contain;

      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      left: 0;
      height: 8%;
      max-height: 127px;
      z-index: 2;
    }

    .elements {
      position: absolute;
      z-index: 1;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      height: 100%;
      width: 100%;

      &.left {
        animation: splashscreen-elements-left 30s linear infinite;
      }
      &.right {
        animation: splashscreen-elements-right 30s linear infinite;
      }

      > * {
        position: absolute;
        background-repeat: no-repeat;
        width: 100%;
      }

      .squiggle {
        background-image: url(/assets/splashscreen/splash-squiggle.svg);
      }
      .lines {
        background-image: url(/assets/splashscreen/splash-lines.svg);
      }
      .circles {
        background-image: url(/assets/splashscreen/splash-circles.svg);
      }
      .corner {
        background-image: url(/assets/splashscreen/splash-corner.svg);
      }

      &.left {
        .squiggle {
          height: 5%;
          top: 60%;
          left: 0;
        }
        .lines {
          top: 12%;
          left: 50%;
          height: 5%;
        }
      }
      &.right {
        .corner {
          height: 25%;
          top: 0;
          right: -18%;
        }
        .circles {
          &.top {
            top: -33%;
            right: 0%;
            height: 31%;
            transform: rotate(-18deg);
          }
          &.bottom {
            bottom: -7%;
            right: -0%;
            height: 31%;
            transform: rotate(182deg);
          }
        }
        .lines {
          top: 70%;
          right: -30%;
          height: 5%;
        }
      }
    }

    @include splash-screen-bg-layers;

    @include generic-mobile {
      background-image: url(/assets/splashscreen/ios/Default@2x~universal~comany.png);
    }

    @include generic-desktop {
      background-position: top;
      background-image: url(/assets/splashscreen/ios/Default@2x~universal~anyany.png);
    }

    @include generic-desktop-wide {
      background-position: top;
      background-image: url(/assets/splashscreen/ios/Default@2x~universal~comcom.png);
    }

    @include ios-2x-universal-anyany {
      background-image: url(/assets/splashscreen/ios/Default@3x~universal~anyany.png);
    }

    @include ios-2x-universal-comany {
      background-image: url(/assets/splashscreen/ios/Default@2x~universal~comany.png);
    }

    @include ios-2x-universal-comcom {
      background-image: url(/assets/splashscreen/ios/Default@2x~universal~comcom.png);
    }

    @include ios-3x-universal-anyany {
      background-image: url(/assets/splashscreen/ios/Default@3x~universal~anyany.png);
    }

    @include ios-3x-universal-comany {
      background-image: url(/assets/splashscreen/ios/Default@3x~universal~comany.png);
    }

    @include ios-3x-universal-comcom {
      background-image: url(/assets/splashscreen/ios/Default@3x~universal~comcom.png);
    }

    &.anim-leave {
      animation: splashscreen-leave 300ms ease;
    }

    &.anim-idle {
    }

    &.hidden {
      display: none;
    }
  }
}
