@use 'utils' as *;

// @import 'node_modules/bootstrap/scss/functions';
// @import 'node_modules/bootstrap/scss/variables';
// @import 'node_modules/bootstrap/scss/mixins';
// @import 'node_modules/bootstrap/scss/popover';

// Popover variable overrides
$popover-bg: $mingaBlue1;
$popover-border-width: 0px;
$popover-border-radius: 6px;
$zindex-popover: 1010;
$popover-inner-padding: 8px;
$font-family: 'Muli', sans-serif;
$popover-body-color: white;
$popover-arrow-width: 16px;
$popover-arrow-height: 10px;
$popover-arrow-outer-width: ($popover-arrow-width + 1px);
$popover-arrow-color: $popover-bg;
$popover-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);

@mixin popover {
  .popover.popover-content .popover-body {
    @include mg-minicopy;

    p {
      color: $popover-body-color;

      &:first-child {
        margin-top: 0;
      }
    }

    a {
      color: $popover-body-color;
    }
  }
}
