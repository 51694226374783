@use 'utils' as *;

@mixin cardStyle {
  .mat-row {
    margin: spacing('2') 0;
    border-radius: radius(medium);
    border: 1px solid get-grey('100');
    background-color: app-color(surface-card);
    height: rem-calc(55);
    padding: spacing('2') spacing('3');
  }
}

// @TODO: Maybe use material theme for this. This was just a migration of the
// old stream table styles
@mixin table {
  $tableBorderRadius: 6px;

  .mg-table table {
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.17);
    background-color: white;
    border-radius: $tableBorderRadius;

    thead {
      font-size: 0.8em;
      color: white;

      th {
        background-color: $mingaBlue1;
      }

      th:first-child {
        border-top-left-radius: $tableBorderRadius;
      }

      th:last-child {
        border-top-right-radius: $tableBorderRadius;
      }
    }

    tr {
      border-bottom-color: var(--table-stream-border-color, black);
      border-bottom-style: solid;
      border-bottom-width: 1px;
    }

    tr:last-child {
      border: none;
    }

    tr td {
      border-left-color: var(--table-stream-border-color, black);
      border-left-style: solid;
      border-left-width: 1px;

      &:first-child {
        border-left: none;
        border-left-width: 0px;
      }
    }

    tr.mg-table-stream-footer td {
      border-bottom-right-radius: $tableBorderRadius;
      border-bottom-left-radius: $tableBorderRadius;
      margin-bottom: 16px;
    }
  }
}

@mixin list-table {
  .list-table {
    background: transparent;
    width: 100%;

    mat-row,
    mat-header-row,
    mat-footer-row {
      word-break: break-word;
      &:after {
        display: none;
      }
    }

    mat-cell,
    mat-header-cell {
      word-break: break-word;
    }

    &.list-table-relaxed {
      .mat-row {
        height: auto;
      }
    }

    .text-left {
      text-align: left;
      justify-content: flex-start;
    }
    .text-right {
      text-align: right;
      justify-content: flex-end;
    }
    .text-center {
      text-align: center;
      justify-content: center;
    }

    @include breakpoint(small down) {
      .mat-row {
        min-height: auto;
        border: none;
        margin: spacing('2') 0;
      }
      .mat-header-row {
        display: none;
      }

      mat-cell {
        display: block;
        padding: 0 !important;
        margin: 0;
        width: 100%;
      }

      mat-cell:not(.mobile-layout) {
        display: none;
      }

      &.no-explicit-mobile-layout {
        mat-cell {
          display: flex;
          width: inherit;
          flex: 1;
        }

        // targets classes like "w-12" or "text-center w-12" but not classes like "tw-flex"
        [class^='w-'],
        [class*=' w-'] {
          flex: none;
        }

        @include cardStyle;
      }

      &.list-table-relaxed {
        .mat-row {
          height: auto;
        }
      }
    }

    @include breakpoint(medium) {
      .mobile-layout {
        display: none;
      }

      .mat-header-row,
      .mat-row {
        flex: auto;
        display: flex;
        gap: spacing('3');
      }

      .mat-header-row {
        height: rem-calc(20);
        min-height: rem-calc(20);
        padding: 0 spacing('3');
        border: none;
      }

      @include cardStyle;

      mat-cell,
      mat-header-cell {
        &:first-of-type {
          padding-left: 0;
        }
        &:last-of-type {
          padding-right: 0;
        }
      }

      // targets classes like "w-12" or "text-center w-12" but not classes like "tw-flex"
      [class^='w-'],
      [class*=' w-'] {
        flex: none;
      }

      .w-sm-flex,
      .w-md-flex,
      .w-lg-flex {
        flex: 1;
      }

      .w-grow {
        max-width: none;
        flex: 1;
      }
    }
  }
}

@mixin data-table {
  .data-table {
    border-radius: radius(small);
    // border: 1px solid get-grey('50');
    overflow: hidden;

    .text-left {
      text-align: left;
      justify-content: flex-start;
    }
    .text-right {
      text-align: right;
      justify-content: flex-end;
    }
    .text-center {
      text-align: center;
      justify-content: center;
    }

    mat-row,
    mat-header-row,
    mat-footer-row {
      border: 1px solid get-grey('50');
      border-color: get-grey('50');
      border-top: none;
      padding-left: spacing('3');
      padding-right: spacing('3');
      gap: spacing('3');

      // this was messing up the flex gap
      &:after {
        display: none;
      }
    }

    mat-cell,
    mat-header-cell {
      word-break: break-word;

      &:first-of-type {
        padding-left: spacing('3');
      }
      &:last-of-type {
        padding-right: spacing('3');
      }
    }

    .mat-header-row {
      min-height: rem-calc(48);
      border: 1px solid get-grey('50');
      background-color: app-color(surface-card);
      border-radius: radius(small) radius(small) 0 0;
    }

    // targets classes like "w-12" or "text-center w-12" but not classes like "tw-flex"
    [class^='w-'],
    [class*=' w-'] {
      flex: none;
    }

    .w-sm-flex,
    .w-md-flex,
    .w-lg-flex {
      flex: 1;
    }

    .w-grow {
      max-width: none;
      flex: 1;
    }

    @include breakpoint(medium) {
      .mobile-only {
        display: none;
      }
    }
    @include breakpoint(small down) {
      .desktop-only {
        display: none;
      }
    }
  }
}
