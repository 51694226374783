@use '@angular/material' as mat;
@use 'utils' as *;

@mixin search($theme, $comp-inactive, $text-inactive) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  mg-navsearch,
  mg-search {
    input {
      color: mat.get-color-from-palette($primary, '600');
    }
    .active {
      color: mat.get-color-from-palette($primary, '600');
    }

    .mat-input-placeholder {
      color: $text-inactive;
    }

    mg-icon,
    mat-icon {
      color: $text-inactive;
    }

    mat-form-field {
      .mat-form-field-underline {
        background-color: $comp-inactive;
      }

      &.mat-focused {
        mg-icon,
        mat-icon {
          color: mat.get-color-from-palette($primary, '600');
        }

        &:not(.mat-form-field-invalid) {
          .mat-form-field-underline {
            .mat-form-field-ripple {
              background-color: mat.get-color-from-palette($primary, 500);
            }
          }
        }
      }
    }
  }
}
