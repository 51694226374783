@use 'utils' as *;

@mixin comment-view($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  mg-comment-view {
    --mg-avatar-size: 20px;

    .comment-content {
      @include user-content($theme, 0.875);

      p:first-child {
        margin-top: 0;
        margin-bottom: 0;
        display: inline-block;
      }

      p:last-child {
        margin-bottom: 0;
      }
    }
  }
}
