@use 'utils' as *;

/**
 * Usage: https://tailwindcss.com/docs/
 *
 * These utility style classes names are ripped off from TailwindCSS. The
 * values (padding, margin… etc) are as close as possible to the Tailwind
 * defaults while using the Minga default values. Read more about utility-
 * first fundamentals: https://tailwindcss.com/docs/utility-first

 * The only difference is all of these classes have the "tw-" prefix on the
 * class name to minimize style collisions with existing classes. When we are
 * ready to switch to Tailwind officially, we can either add the prefix in
 * the Tailwind config (https://tailwindcss.com/docs/configuration#prefix),
 * or we can do a global replace for "tw-"
 * 
 * Not all Tailwind classes have been added. Classes are added as they are
 * needed. Continuing to add classes here will not be sustainable long term
 * as there are a lot of rules, and no purgecss to remove unused classes at 
 * this time in the project.
 * 
 * At first glance adding these extra rules may seem like a waste, but the
 * more components that use them, then we can reduce our CSS bundle size
 * over time.
 * 
 * For example: the rule "justify-content: center" is defined 183 times in 
 * files all over the app, and has 183 different class names of various lengths
 * in the CSS and html. Compare that to one definition, a set name length,
 * (tw-justify-center), and used in 183 places.
 * 
 * Foreseeable challenges: overriding material styles using these utility
 * classes. One strategy to resolve this is to keep the one off style rules
 * and then in the future try to replace material components with our own.
 * 
 * Note: widths, radii, breakpoints and many other classes are currently 
 * defined in _settings.scss.
 * 
 * When defining breakpoint styles in this file, make sure the class comes
 * after the base class.
 */

/** Display */
.tw-hidden {
  display: none;
}

.tw-block {
  display: block;
}

.tw-inline-block {
  display: inline-block;
}

.tw-grid {
  display: grid;
}

.tw-flex {
  display: flex;
}

.tw-flex-1 {
  flex: 1;
}

.sm\:tw-flex {
  @include breakpoint(small) {
    display: flex;
  }
}

.sm\:tw-hidden {
  @include breakpoint(small) {
    display: none;
  }
}

/** Position */
.tw-absolute {
  position: absolute;
}

.tw-relative {
  position: relative;
}

/** Z-index */
.tw-z-10 {
  z-index: 10;
}

/** Position */
.tw-inset-0 {
  inset: 0;
}

/** Overflow */
.tw-overflow-hidden {
  overflow: hidden;
}

/** Flex Wrap */
.tw-flex-wrap {
  flex-wrap: wrap;
}

/** Flex Grow */
.tw-grow {
  flex-grow: 1;
}

.tw-grow-0 {
  flex-grow: 0;
}

.sm\:tw-grow-0 {
  @include breakpoint(small) {
    flex-grow: 0;
  }
}

/** Flex Shrink */
.tw-shrink {
  flex-shrink: 1;
}

.tw-shrink-0 {
  flex-shrink: 0;
}

/**
 * Arbitrary breakpoint value for tailwindcss, this breakpoint should be
 * added to the tailwind theme when we adopt tailwind.
 */
.sm\:tw-flex-nowrap {
  @include breakpoint(small) {
    flex-wrap: nowrap;
  }
}

/** Flex Direction */
.tw-flex-row {
  flex-direction: row;
}

.tw-flex-column {
  flex-direction: column;
}

.md\:tw-flex-row {
  @include breakpoint(medium) {
    flex-direction: row;
  }
}

.sm\:tw-flex-row {
  @include breakpoint(small) {
    flex-direction: row;
  }
}

/** Margin */
.tw-m-0 {
  margin: 0;
}

.tw-mt-1 {
  margin-top: spacing('1');
}

.tw-mt-2 {
  margin-top: spacing('2');
}

.tw-mt-3 {
  margin-top: spacing('3');
}

.tw-mt-4 {
  margin-top: spacing('4');
}

.tw-mt-5 {
  margin-top: spacing('5');
}

.tw-mt-6 {
  margin-top: spacing('6');
}

.tw-ml-auto {
  margin-left: auto;
}

.tw-mb-1 {
  margin-bottom: spacing('1');
}

.tw-mb-2 {
  margin-bottom: spacing('2');
}

.tw-mb-2\.5 {
  margin-bottom: spacing('2-half');
}

.tw-mb-3 {
  margin-bottom: spacing('3');
}

.tw-mb-4 {
  margin-bottom: spacing('4');
}

.tw-mb-6 {
  margin-bottom: spacing('6');
}

.tw-mb-8 {
  margin-bottom: spacing('8');
}

.tw-my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.md\:tw-mb-2\.5 {
  @include breakpoint(medium) {
    margin-bottom: spacing('2-half');
  }
}

/** Padding */
.tw-p-2 {
  padding: spacing('2'); // 8px
}

.tw-p-3 {
  padding: spacing('3'); // 12px
}

.tw-p-4 {
  padding: spacing('4'); // 16px
}

.tw-p-6 {
  padding: spacing('6'); // 24px
}

.tw-pt-3 {
  padding-top: spacing('3'); // 12px
}

.tw-pb-1 {
  padding-bottom: spacing('1'); // 4px
}

.tw-pb-2 {
  padding-bottom: spacing('2'); // 8px
}

.tw-pb-4 {
  padding-bottom: spacing('4'); // 16px
}

.tw-pl-0 {
  padding-left: 0;
}

/** Using !important here because of overly aggressive table cell style */
.tw-pr-0 {
  padding-right: 0 !important;
}

.tw-px-1 {
  padding-left: spacing('1'); // 4px
  padding-right: spacing('1'); // 4px
}

.tw-px-1\.5 {
  padding-left: spacing('1-half'); // 6px
  padding-right: spacing('1-half'); // 6px
}

.tw-py-0\.5 {
  padding-top: spacing('0-half'); // 2px
  padding-bottom: spacing('0-half'); // 2px
}

.tw-py-1 {
  padding-top: spacing('1'); // 4px
  padding-bottom: spacing('1'); // 4px
}

.tw-py-1\.5 {
  padding-top: spacing('1-half'); // 6px
  padding-bottom: spacing('1-half'); // 6px
}

.tw-py-2 {
  padding-top: spacing('2'); // 8px
  padding-bottom: spacing('2'); // 8px
}

.tw-py-3 {
  padding-top: spacing('3'); // 12px
  padding-bottom: spacing('3'); // 12px
}

.tw-py-4 {
  padding-top: spacing('4'); // 16px
  padding-bottom: spacing('4'); // 16px
}

.tw-pr-2 {
  padding-right: spacing('2'); // 8px
}

.tw-pr-4 {
  padding-right: spacing('4'); // 16px
}

.sm\:tw-py-1 {
  @include breakpoint(small) {
    padding-top: spacing('1'); // 4px
    padding-bottom: spacing('1'); // 4px
  }
}

/** Flex Basis */
.tw-basis-full {
  flex-basis: 100%;
}

.sm\:tw-basis-full {
  @include breakpoint(small) {
    flex-basis: 100%;
  }
}

/** User Select */
.tw-select-none {
  user-select: none;
}

/**
 * Arbitrary breakpoint value for tailwindcss, this breakpoint should be
 * added to the tailwind theme when we adopt tailwind.
 */
.tw-min-\[320px\]\:tw-basis-1\/2 {
  @media (min-width: 320px) {
    flex-basis: 45%;
  }
}

/** Space Between - target the children */
.tw-space-y-2 > * + * {
  margin-top: spacing('2');
}

/** Divide */
.tw-divide-y > * + * {
  border-top-width: 1px;
  border-bottom-width: 0px;
}

/** Flex & Grid Spacing */
.tw-gap-0\.5 {
  gap: spacing('0-half'); // 2px
}

.tw-gap-1 {
  gap: spacing('1'); // 4px
}

.tw-gap-2 {
  gap: spacing('2'); // 8px
}

.tw-gap-2\.5 {
  gap: spacing('2-half'); // 10px
}

.tw-gap-3 {
  gap: spacing('3'); // 12px
}

.tw-gap-4 {
  gap: spacing('4'); // 16px
}

.tw-gap-5 {
  gap: spacing('5'); // 20px
}

.tw-gap-6 {
  gap: spacing('6'); // 24px
}

.tw-gap-x-1 {
  column-gap: spacing('1'); // 4px
}

.tw-column-gap-2 {
  column-gap: spacing('2'); // 8px
}

.tw-column-gap-3 {
  column-gap: spacing('3'); // 12px
}

.tw-row-gap-3 {
  row-gap: spacing('3'); // 12px
}

.tw-row-gap-4 {
  row-gap: spacing('4'); // 16px
}

.tw-row-gap-6 {
  row-gap: spacing('6'); // 24px
}

.sm\:tw-gap-y-0 {
  @include breakpoint(small) {
    row-gap: 0;
  }
}

.md\:tw-gap-3 {
  @include breakpoint(medium) {
    gap: spacing('3'); // 12px
  }
}

.md\:tw-gap-4 {
  @include breakpoint(medium) {
    gap: spacing('4'); // 16px
  }
}

/** Grid column start/end */
.md\:tw-col-start-2 {
  @include breakpoint(medium) {
    grid-column-start: 2;
  }
}

.md\:tw-row-start-1 {
  @include breakpoint(medium) {
    grid-row-start: 1;
  }
}

.md\:tw-row-end-3 {
  @include breakpoint(medium) {
    grid-row-end: 3;
  }
}

.md\:tw-col-span-2 {
  @include breakpoint(medium) {
    grid-column: span 2 / span 2;
  }
}

/** Grid row start/end */
.md\:tw-row-span-3 {
  @include breakpoint(medium) {
    grid-row: span 3 / span 3;
  }
}

/** Order */
.md\:tw-order-2 {
  @include breakpoint(medium) {
    order: 2;
  }
}

/** Grid Columns */
.tw-grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.tw-grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.tw-grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.tw-grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.tw-grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.sm\:tw-grid-cols-2 {
  @include breakpoint(small) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.md\:tw-grid-cols-1 {
  @include breakpoint(medium) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

.md\:tw-grid-cols-2 {
  @include breakpoint(medium) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.md\:tw-grid-cols-3 {
  @include breakpoint(medium) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.md\:tw-grid-cols-4 {
  @include breakpoint(medium) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

.md\:tw-grid-cols-5 {
  @include breakpoint(medium) {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
}

/** Flex & Grid Alignment */
.tw-justify-start {
  justify-content: flex-start;
}

.tw-justify-center {
  justify-content: center;
}

.tw-justify-end {
  justify-content: flex-end;
}

.tw-justify-stretch {
  justify-content: stretch;
}

.tw-justify-between {
  justify-content: space-between;
}

.tw-justify-evenly {
  justify-content: space-evenly;
}

.tw-justify-items-stretch {
  justify-items: stretch;
}

.tw-justify-self-end {
  justify-self: end;
}

.tw-items-center {
  align-items: center;
}

.tw-items-stretch {
  align-items: stretch;
}

.tw-items-end {
  align-items: end;
}

.sm\:tw-justify-start {
  @include breakpoint(small) {
    justify-content: flex-start;
  }
}

.sm\:tw-justify-self-end {
  @include breakpoint(small) {
    justify-self: end;
  }
}

.sm\:tw-items-center {
  @include breakpoint(small) {
    align-items: center;
  }
}

.md\:tw-items-center {
  @include breakpoint(medium) {
    align-items: center;
  }
}

/** Height */
.tw-h-2\.5 {
  height: rem-calc(10);
}

.tw-h-4 {
  height: rem-calc(16);
}

.tw-h-5 {
  height: rem-calc(20);
}

.tw-h-6 {
  height: rem-calc(24);
}

// TODO:
// h-7.5 is not a standard TailWindCSS size. This was added to match the Figma designs. This
// should be replaced with an arbitrary size from the tailwind library or use the closest match.
.tw-h-7\.5 {
  height: rem-calc(30);
}

.tw-h-10 {
  height: rem-calc(40);
}

.tw-h-14 {
  height: rem-calc(56);
}

.tw-h-24 {
  height: rem-calc(96);
}

.tw-h-52 {
  height: rem-calc(208);
}

.tw-h-full {
  height: 100%;
}

/** Min-Height */
.tw-min-h-fit {
  min-height: fit-content;
}

/** Border Radius */
.tw-rounded-xs {
  border-radius: radius(xsmall); // 2px
}

.tw-rounded-sm {
  border-radius: radius(small); // 5px
}

.tw-rounded-md {
  border-radius: radius(medium); // 8px
}

.tw-rounded-lg {
  border-radius: radius(large); // 10px
}

.tw-rounded-xl {
  border-radius: radius(xlarge); // 20px
}

.tw-rounded-full {
  border-radius: radius(full); // 9999px;
}

.sm\:tw-rounded-md {
  @include breakpoint(small) {
    border-radius: radius(medium); // 8px
  }
}

/** Aspect Ratio */
.tw-aspect-square {
  aspect-ratio: 1;
}

.tw-aspect-video {
  aspect-ratio: 16 / 9;
}

/** Border Width */
.tw-border {
  border-width: 1px;
}

.tw-border-b {
  border-bottom-width: 1px;
}

/** Border Style */
.tw-border-none {
  border-style: none;
}

.tw-border-solid {
  border-style: solid;
}

.sm\:tw-border-solid {
  @include breakpoint(small) {
    border-style: solid;
  }
}

/** Border Width */
.tw-border-0 {
  border-width: 0;
}

.sm\:tw-border {
  @include breakpoint(small) {
    border-width: 1px;
  }
}

/** Border Colour */
.tw-border-gray-300,
.tw-divide-gray-300 {
  // Not exact match, TW: rgb(209 213 219)
  border-color: get-grey('100'); // Minga: rgb(209, 210, 213)
}

/** Background Colour */
.tw-bg-gray-50 {
  // Not exact match, TW: rgb(249 250 251)
  background-color: app-color(surface-card); // Minga: rgb(250, 249, 253)
}

.tw-bg-gray-400 {
  // Not exact match, TW: rgb(156 163 175)
  background-color: get-grey('200'); // Minga: rgb(185, 187, 192)
}

/** Opacity */
.tw-opacity-30 {
  opacity: 0.3;
}

/** List Style Type */
.tw-list-none {
  list-style-type: none;
}

/**
 * Width
 * 
 * Defining these width classes here because _settings.scss uses a non-standard
 * naming convention of w-1-2
 */
.tw-w-16 {
  width: width('w-16'); // 64px
}

.tw-w-full {
  width: 100%;
}

.tw-w-1\/2 {
  width: 50%;
}

.md\:tw-w-1\/2 {
  @include breakpoint(medium) {
    width: 50%;
  }
}

.tw-w-1\/3 {
  width: 33.333333%;
}

.tw-w-2\/3 {
  width: 66.666667%;
}

.tw-w-1\/4 {
  width: 25%;
}

.tw-w-3\/4 {
  width: 75%;
}

.tw-w-2\/5 {
  width: 40%;
}

.tw-w-1\/12 {
  width: 8.333333%;
}

.tw-w-2\/12 {
  width: 16.666667%;
}

.tw-w-3\/12 {
  width: 25%;
}

/** Min-Width */
.tw-min-w-14 {
  min-width: width('w-14'); // 56px
}

.tw-min-w-16 {
  min-width: width('w-16'); // 64px
}

.tw-min-w-24 {
  min-width: width('w-24'); // 96px
}

.tw-min-w-28 {
  min-width: width('w-28'); // 112px
}

.tw-min-w-32 {
  min-width: width('w-32'); // 128px
}

.tw-min-w-40 {
  min-width: width('w-40'); // 160px
}

.tw-min-w-48 {
  min-width: width('w-48'); // 192px
}

.tw-min-w-56 {
  min-width: width('w-56'); // 224px
}

.tw-min-w-64 {
  min-width: width('w-64'); // 256px
}

.md\:tw-min-w-24 {
  @include breakpoint(medium) {
    min-width: width('w-24'); // 96px
  }
}

.md\:tw-min-w-28 {
  @include breakpoint(medium) {
    min-width: width('w-28'); // 112px
  }
}

/** Max-Width */
.tw-max-w-14 {
  max-width: width('w-14'); // 56px
}

.tw-max-w-16 {
  max-width: width('w-16'); // 64px
}

.tw-max-w-24 {
  max-width: width('w-24'); // 96px
}

.tw-max-w-28 {
  max-width: width('w-28'); // 112px
}

.tw-max-w-32 {
  max-width: width('w-32'); // 128px
}

.tw-max-w-40 {
  max-width: width('w-40'); // 160px
}

.tw-max-w-56 {
  max-width: width('w-56'); // 224px
}

.tw-max-w-60 {
  max-width: width('w-60'); // 160px
}

.md\:tw-max-w-28 {
  @include breakpoint(medium) {
    max-width: width('w-28'); // 112px
  }
}

/* Text Align */
.tw-text-left {
  text-align: left;
}
.tw-text-right {
  text-align: right;
}

.tw-text-justify {
  text-align: justify;
}

.tw-text-start {
  text-align: start;
}

.tw-text-end {
  text-align: end;
}
