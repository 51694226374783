@use 'utils' as *;

@mixin quill {
  .ql-toolbar.ql-snow,
  .ql-snow.ql-container,
  .ql-editor.ql-blank::before {
    border: none !important;
    @include mg-bodycopy;
  }

  .ql-toolbar {
    transition: max-height bezierTransition(), padding bezierTransition();
  }

  quill-editor.hide-toolbar {
    .ql-toolbar {
      max-height: 0;
      overflow: hidden;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }

    .ql-editor {
      padding-bottom: 6px;

      p:last-child {
        margin-bottom: 0;
      }
    }
  }

  quill-editor.is-disabled {
    .ql-toolbar {
      visibility: hidden;

      span:last-child {
        visibility: visible;
      }
    }
  }

  .ql-editor.ql-blank {
    &::before {
      font-style: normal;
    }

    &:focus {
      &::before {
        opacity: 0;
      }
    }
  }

  .ql-editor {
    @include mg-bodycopy;

    padding: 14px;
    padding-top: 6px;

    p:first-child {
      margin-top: 0px !important;
    }

    transition: max-height bezierTransition(), padding bezierTransition();
  }

  .ql-toolbar.ql-snow {
    border-bottom: none;
  }

  .ql-editor {
    width: 100%;
    height: auto !important;
  }

  .ql-snow .ql-editor {
    @include user-content($minga-app-content-dark-theme);
  }
}
