@use 'utils' as *;

@mixin top-of-feed($theme) {
  .text-row {
    padding: 5px;
    background-color: $mingaBlue1;
    color: white;
    font-size: 14px;
    text-align: center;
  }

  .prefix {
    font-weight: bold;
    margin-right: 5px;
  }
}
