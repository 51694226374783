@use 'utils' as *;

// @TODO: Improve this for a generic approach as some places still have issues..
@mixin ios() {
  body {
    /* default to 0px, if no notch nothing is effected and has a defined value */
    --mg-ios-notch-offset: 0px;
  }

  /* iPhone X: For top or side of page padding to avoid the notch */
  @media (orientation: landscape) {
    body.device-ios {
      padding-left: constant(safe-area-inset-top); /* iOS 11.0 */
      padding-right: constant(safe-area-inset-top); /* iOS 11.0 */
      padding-left: max(12px, env(safe-area-inset-top)); /* iOS 11.2 */
      padding-right: max(12px, env(safe-area-inset-top)); /* iOS 11.2 */
    }
  }
  @media (orientation: portrait) {
    body.device-ios {
      /* variable to track how much to offset content from notch */
      /* @NOTE: This value changes from zero to the safe-area-inset-top value
      * for example: 0 when app inits, but 48px once camera is opened...
      */
      --mg-ios-notch-offset: max(0px, env(safe-area-inset-top));

      /* This element pushes down mobile overlays and app content with the notch
      offset amount (located in app.component) */
      .ios-notch-offset {
        height: var(--mg-ios-notch-offset);
      }

      /* position fixed top: 0 elements need adjusting if notch exists */
      mg-top-nav,
      .mg-sidenav-container.fixed.mobile .mg-sidenav {
        top: var(--mg-ios-notch-offset);
      }
    }
  }

  /* @TODO: possibly use this for bottom of page iPhone X support */
  @media (orientation: portrait) {
    .footer-iphonex {
      margin-left: constant(safe-area-inset-bottom); /* iOS 11.0 */
      margin-right: constant(safe-area-inset-bottom); /* iOS 11.0 */
      margin-left: env(safe-area-inset-bottom); /* iOS 11.2 */
      margin-right: env(safe-area-inset-bottom); /* iOS 11.2 */
    }
  }
  @media (orientation: landscape) {
    .footer-iphonex {
      margin-bottom: constant(safe-area-inset-bottom); /* iOS 11.0 */
      margin-bottom: env(safe-area-inset-bottom); /* iOS 11.2 */
    }
  }
}
