@use '@angular/material' as mat;
@use 'utils' as *;

@mixin button(
  $theme,
  $disabledColor: $md-disabled,
  $lightColorPallette: $md-mingablue,
  $darkGreyPallette: $md-cardgrey
) {
  $accent: map-get($theme, accent);
  $primary: map-get($theme, primary);
  $warn: map-get($theme, warn);
  $disabled: mat.get-color-from-palette($disabledColor, 500);
  $lightColor: mat.get-color-from-palette($lightColorPallette, 500);
  $darkGreyColor: mat.get-color-from-palette($darkGreyPallette, 500);

  mg-button:not(.no-active):not(.disabled) {
    .active-icon {
      opacity: 0 !important;
    }
    .default-icon {
      opacity: 1 !important;
    }
  }

  .active mg-button:not(.no-active):not(.disabled) {
    .active-icon {
      opacity: 1 !important;
    }
    .default-icon {
      opacity: 0 !important;
    }
  }

  @media (hover: hover) {
    .mg-button-hover-harbour:hover
      mg-button:not(.no-active):not(.disabled)
      button,
    mg-button:not(.no-active):not(.disabled) button:hover {
      background-color: var(--mg-button-color, mat.get-color-from-palette($primary, 400));
      .active-icon {
        opacity: 0 !important;
      }
      .default-icon {
        opacity: 0.7 !important;
      }
    }

    .mg-button-hover-harbour.active:hover
      mg-button:not(.no-active):not(.disabled)
      button,
    .active
      .mg-button-hover-harbour:hover
      mg-button:not(.no-active):not(.disabled)
      button,
    .active mg-button:not(.no-active):not(.disabled) button:hover {
      .active-icon {
        opacity: 0.7 !important;
      }
      .default-icon {
        opacity: 0 !important;
      }
    }
  }

  mg-button {
    white-space: normal;

    &:first-child {
      button {
        padding-left: 0;
      }
    }
    &:last-child {
      button {
        padding-right: 0;
      }
    }

    // default to color=primary
    button {
      background-color: var(--mg-button-color, mat.get-color-from-palette($primary, 500));
      color: white;
      border-color: transparent;
      white-space: inherit;
      // to fix safari's built in styles of margin zeroing that doesn't actually work
      margin: 0;
    }

    button:disabled {
      background-color: $disabled;
    }
    &[color='accent'] {
      button {
        background-color: mat.get-color-from-palette($accent, 500);
      }
    }
    &[color='warn'] {
      button {
        background-color: mat.get-color-from-palette($warn, 500);
      }
    }
    &[color='gradient'],
    &.active:not(.disabled) {
      button {
        background: $mg-gradient;
      }
    }
    &[color='grey'] {
      button {
        background-color: $darkGreyColor;
      }
    }
    &[color='green'] {
      button {
        background-color: $mingaGreen1;
      }
    }
    &[color='light'] {
      &[type*='outline'] {
        button {
          color: $lightColor;
        }
      }
      &:not([type*='outline']) {
        button {
          background-color: $lightColor;
        }
      }
    }

    // gradient ripple effect
    .mat-ripple-element {
      background: $mg-gradient;
      // background: mat-color($primary, 500);
      opacity: 0.5;
    }

    %activeState {
      color: mat.get-color-from-palette($accent, 300) !important;
    }

    &[type*='raised'] {
      button {
        box-shadow: inset 0 -6px 0 0 darken(mat.get-color-from-palette($primary, 500), 20);
        border: none;
      }
      &[color='light'] {
        button {
          box-shadow: inset 0 -6px 0 0 darken($lightColor, 20);

          @media (hover: none) {
            &:active {
              background-color: darken($lightColor, 20);
            }
          }

          @media (hover: hover) {
            &:hover {
              background-color: darken($lightColor, 20);
            }
          }
        }
      }
    }

    // Text, Clear and outline button theming
    &[type*='clear'],
    &[type*='outline'],
    &[type*='text'] {
      // default color text to primary
      button {
        color: var(--mg-button-color, mat.get-color-from-palette($primary, 500));
        background: var(--mg-button-background, transparent);
      }
      &.active:not(.disabled) {
        color: white;
      }

      button:disabled {
        color: $disabled;
      }
      &[color='accent'] {
        button {
          color: mat.get-color-from-palette($accent, 500);
        }
      }
      &[color='warn'] {
        button {
          color: mat.get-color-from-palette($warn, 500);
        }
      }
      &[color='grey'] {
        button {
          color: $darkGreyColor;
        }
      }
      &[color='green'] {
        button {
          color: $mingaGreen1;
        }
      }
      &[color='gradient'] {
        button {
          background: mat.get-color-from-palette($primary, 500);
          // background: $mg-gradient;
          // -webkit-mask-image: $mg-gradient;
          -webkit-mask-image: mat.get-color-from-palette($primary, 500);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          @media (hover: hover) {
            &:hover {
              $primaryHoverColor: mat.get-color-from-palette($primary, 300);
              background: linear-gradient(
                90deg,
                $primaryHoverColor,
                $primaryHoverColor
              );
              -webkit-background-clip: text;
            }
          }
        }

        // mg-button-icon or mg-button type="icon" gradient
        mg-icon,
        mg-icon mat-icon {
          fill: url(#mg-gradient1);
          // @TODO: remove 'svg path' when in the svg file fill is not in the svg path..
          svg path {
            fill: url(#mg-gradient1) !important;
          }
        }
      }
    }

    &[type*='outline'] {
      %activeStateBorder {
        border-color: mat.get-color-from-palette($accent, 500);
      }

      &:not(.no-active):not(.disabled) {
        @media (hover: hover) {
          button:hover {
            color: white;
            background-color: var(
              --mg-button-color,
              mat.get-color-from-palette($primary, 500)
            ) !important;
          }
        }
      }

      button {
        border-width: var(--mg-button-border-width, 1px);
        border-style: solid;

        // default color=primary
        border-color: var(--mg-button-color, mat.get-color-from-palette($primary, 500));
      }
      button:disabled {
        color: $disabled;
        border-color: $disabled;
      }

      &[color='accent'] {
        button {
          border-color: mat.get-color-from-palette($accent, 500);
        }

        &:not(.no-active):not(.disabled) {
          @media (hover: hover) {
            button:hover {
              color: white;
              background-color: mat.get-color-from-palette($accent, 500) !important;
            }
          }
        }
      }
      &[color='warn'] {
        button {
          border-color: mat.get-color-from-palette($warn, 500);
        }

        &:not(.no-active):not(.disabled) {
          @media (hover: hover) {
            button:hover {
              color: white;
              background-color: mat.get-color-from-palette($warn, 500) !important;
            }
          }
        }
      }
      &[color='grey'] {
        button {
          border-color: $darkGreyColor;
        }

        &:not(.no-active):not(.disabled) {
          @media (hover: hover) {
            button:hover {
              color: white;
              background-color: $darkGreyColor !important;
            }
          }
        }
      }
      &[color='green'] {
        button {
          border-color: $mingaGreen1;
        }

        &:not(.no-active):not(.disabled) {
          @media (hover: hover) {
            button:hover {
              color: white;
              background-color: $mingaGreen1 !important;
            }
          }
        }
      }
      &[color='gradient'] {
        button {
          border-image: $mg-gradient;
          border-image-slice: 1;
        }
      }
      &[color='light'] {
        button {
          color: $lightColor;
          border-color: $lightColor;
        }
        &:not(.no-active):not(.disabled) {
          @media (hover: hover) {
            button:hover {
              color: white;
              background-color: $lightColor !important;
            }
          }
        }
      }
    }

    &[type*='outline'],
    &[type*='clear'] {
      .mat-ripple-element {
        background: $mg-gradient;
        opacity: 0.2;
      }
    }
    &[type*='text'] {
      .mat-ripple-element {
        display: none;
      }

      // default color text to link
      button {
        color: mat.get-color-from-palette($accent, 500);
        background: transparent;

        @media (hover: hover) {
          &:hover {
            text-decoration: underline;
          }
        }
      }
      &:not(.no-active):not(.disabled) {
        @media (hover: hover) {
          button:hover {
            background-color: transparent;
          }
        }
      }
    }
  }

  mg-button-icon {
    // default coloring to color=primary
    color: var(--mg-button-color, mat.get-color-from-palette($primary, 500));

    mg-button {
      .disabled,
      button:disabled {
        mg-icon,
        mg-icon mat-icon {
          fill: $disabled;
          // @TODO: remove 'svg path' when in the svg file fill is not in the svg path..
          svg path {
            fill: $disabled !important;
          }
        }
      }
    }

    &[color='accent'] {
      button {
        color: mat.get-color-from-palette($accent, 500);
      }
    }
    &[color='warn'] {
      button {
        color: mat.get-color-from-palette($warn, 500);
      }
    }
    &[color='light'] {
      button {
        color: $lightColor;
      }
    }
  }
}

.btn-reset {
  @include reset-button;
}
