@use '@angular/material' as mat;
@use 'utils' as *;

@mixin content-input($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  mg-comment-input {
    .post-btn-wrap .post-btn {
      color: mat.get-contrast-color-from-palette($primary, 500);
      background-color: mat.get-color-from-palette($primary, 600);
    }
    .close-icon {
      color: #737373;
    }
  }
}
