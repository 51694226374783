@use 'utils' as *;

@mixin generate-width-utils() {
  @each $option, $val in map-get($app-theme, 'width') {
    .#{$option} {
      width: $val;
    }
  }

  .w-sm-flex,
  .w-md-flex,
  .w-lg-flex {
    // width: 100%;
    flex: 1;
  }
  .w-sm-flex {
    min-width: rem-calc(100);
    max-width: rem-calc(250);
  }
  .w-md-flex {
    min-width: rem-calc(150);
    max-width: rem-calc(250);
  }
  .w-lg-flex {
    min-width: rem-calc(150);
    max-width: rem-calc(400);
  }

  .w-auto-max {
    max-width: none;
  }
}
