@use '@angular/material' as mat;
@use 'utils' as *;

@mixin dialog($theme, $error, $textBlack) {
  $primary: map-get($theme, primary);

  mat-dialog-container {
    position: relative;
    border-radius: 6px;
    min-width: 256px; // matching the max-width 80vw of iphone 5 for dialogs
    @include mg-minicopy();

    &.mat-dialog-container {
      @media (max-width: $iphone6Width - 1) {
        padding: 20px 16px;
      }
    }

    .btn-wrap {
      margin-top: 8px;
    }

    a {
      color: $linkColor;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }

      &.cancel-link {
        padding-right: 1.5em;
      }
    }
  }

  .top-title {
    margin-top: 2em;

    @include mg-headline();
  }

  .dialog-title {
    margin-top: 2em;

    @include mg-bodycopy-bold();
  }

  .body-text {
    color: $textBlack;
  }

  mg-button-icon {
    &.top {
      position: absolute;
      top: 0;
      padding-top: 14px;
      padding-bottom: 24px;

      @media (max-width: $iphone6Width - 1) {
        padding-top: 10px;
      }
    }

    &.top.right {
      right: 0;
      // large clickable area
      padding-left: 24px;
      padding-right: 17px;

      @media (max-width: $iphone6Width - 1) {
        padding-right: 13px;
      }
    }

    &.top.left {
      left: 0;
      // large clickable area
      padding-right: 24px;
      padding-left: 17px;

      @media (max-width: $iphone6Width - 1) {
        padding-left: 13px;
      }
    }

    &.bottom {
      position: absolute;
      bottom: 0;
      padding-top: 12px;
      padding-bottom: 4px;
    }

    &.bottom.right {
      right: 0;
      // large clickable area
      padding-left: 24px;
      padding-right: 17px;

      @media (max-width: $iphone6Width - 1) {
        padding-right: 13px;
      }
    }
  }

  .color-error {
    color: mat.get-color-from-palette($error, 500);
  }

  .footer-button-container {
    text-align: var(--mg-fbc-align, right);
    display: var(--mg-fbc-display, inherit);
    justify-content: var(--mg-fbc-justify, inherit);

    > * {
      margin-left: 8px;
    }
  }
}
