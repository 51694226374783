@use '@angular/material' as mat;
@use 'utils' as *;

@mixin content($theme, $mdBlack: $md-black) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $textBlack: mat.get-color-from-palette($mdBlack, 500);

  // @TODO: Fill in some of the contents colors with this method. Right now they
  //        need more than primary and accent colors.

  mg-long-card {
    .body-content {
      @include user-content($theme);
    }
  }

  // @NOTE: For the polymer elements they will have to expose their colors as
  //        variables due to the encapsulation.
}
