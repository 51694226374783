@use '@angular/material' as mat;
@use 'utils' as *;

@mixin landing($theme, $landingBoxShadowColor) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  mg-landing .landing-content-bg {
    background-color: mat.get-color-from-palette($primary, 500);
    // box-shadow: 0px 0px 4px mat-color($primary, 500);
    box-shadow: 0px 0px 4px $landingBoxShadowColor;
  }

  mg-landing {
    a {
      color: mat.get-contrast-color-from-palette($primary, 500);
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
