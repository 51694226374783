@use 'utils' as *;

@mixin usercontent {
  .user-content {
    @include user-content($minga-app-content-theme);
  }
  .user-content-page {
    @include user-content($minga-page-theme);
  }
  .small-user-content {
    @include user-content($minga-app-content-theme, 0.8125);
  }
  .small-user-content-page {
    @include user-content($minga-page-theme, 0.8125);
  }
}
