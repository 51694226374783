@use '../vendors/sassy-lists' as *;
@use './math' as *;
@use './misc' as *;
@use 'sass:meta' as *;
@use 'sass:math' as *;

$unit-warnings: true !default;

$global-font-size: 100% !default;

@mixin vunit(
  $propName,
  $propValue,
  $offset: 0,
  $portrait: false,
  $invert: false,
  $portraitOnly: false
) {
  $landscapeValue: $propValue;
  $portraitValue: $propValue;
  @if $portrait == false {
    $landscapeValue: $propValue + $offset;
  } @else {
    $portraitValue: $propValue + $offset;
  }

  @if $portraitOnly {
    @if $invert == false {
      #{$propName}: $portraitValue + vw;
    } @else {
      #{$propName}: $portraitValue + vh;
    }
  } @else {
    @media (orientation: landscape) {
      @if $invert == false {
        #{$propName}: $landscapeValue + vh;
      } @else {
        #{$propName}: $landscapeValue + vw;
      }
    }

    @media (orientation: portrait) {
      @if $invert == false {
        #{$propName}: $portraitValue + vw;
      } @else {
        #{$propName}: $portraitValue + vh;
      }
    }
  }
}

@function strip-unit($num) {
  @return divide($num, $num * 0 + 1);
}

@function rem-calc($values, $base: null) {
  $rem-values: ();
  $count: length($values);

  @if $base ==null {
    $base: $global-font-size;
  }
  @if unit($base) == '%' {
    $base: divide($base, 100%) * 16px;
  }
  @if unit($base) == 'rem' {
    $base: strip-unit($base) * 16px;
  }
  @if $count ==1 {
    @return -zf-to-rem($values, $base);
  }
  @for $i from 1 through $count {
    $rem-values: append($rem-values, -zf-to-rem(nth($values, $i), $base));
  }
  @return $rem-values;
}

@function zf-bp-to-em($value) {
  @if unit($value) == 'px' or unitless($value) {
    $value: rem-calc($value, $base: 16px);
  }
  @return strip-unit($value) * 1em;
}

@function -zf-to-rem($value, $base: null) {
  @if type-of($value) != 'number' {
    @if $unit-warnings {
      @warn inspect($value)+' was passed to rem-calc(), which is not a number.';
    }
    @return $value;
  }
  @if unit($value) == 'em' {
    $value: strip-unit($value) * 1rem;
  }
  @if unit($value) != 'rem' {
    $value: divide(strip-unit($value), strip-unit($base)) * 1rem;
  }
  @if $value ==0rem {
    $value: 0;
  }
  @return $value;
}

@function unitless-calc($value, $base: null) {
  @if $base ==null {
    $base: $global-font-size;
  }
  @if unit($base) == '%' {
    $base: divide($base, 100%) * 16px;
  }
  @if unit($base) == 'rem' {
    $base: strip-unit($base) * 16px;
  }
  @if unit($base) == 'em' {
    $base: strip-unit($base) * 16px;
  }
  @if unit($value) == '%' {
    $value: divide($value, 100%) * $base;
  }
  @if unit($value) == 'rem' {
    $value: strip-unit($value) * $base;
  }
  @if unit($value) == 'em' {
    $value: strip-unit($value) * $base;
  }
  @if unit($value) == 'px' {
    @return divide(strip-unit($value), strip-unit($base));
  }
  @if unitless($value) and ($value > 10) {
    @return divide($value, strip-unit($base));
  }
  @return $value;
}
