@use 'utils' as *;

// generate utility classes for app colors
// eg .app-color-primary, .app-bg-color-primary
@mixin generate-app-colors() {
  @each $option, $val in map-get($app-theme, 'color') {
    .app-color-#{'' + $option} {
      color: $val;
    }
  }

  @each $option, $val in map-get($app-theme, 'color') {
    .app-color-bg-#{'' + $option} {
      background-color: $val;
    }
  }
}
