@use 'utils' as *;

@mixin tab-group-header {
  mg-tab-group-header {
    justify-content: center;

    .tab-group-header {
      display: flex;
      flex-direction: row;
      justify-content: inherit;
    }

    mg-tab-group-header-item {
      cursor: pointer;
      display: block;
      margin: 8px 4px;
      color: $mingaColor1;
      font-size: 12px;
      font-weight: bold;
      padding: 4px 12px;
      border-radius: 1em;

      @media (hover: hover) {
        &:hover {
          background-color: transparentize($mingaBlue3, 0.5);
        }
      }

      &.mg-tab-group-header-item-active {
        background-color: $mingaBlue3;
      }
    }
  }
}
