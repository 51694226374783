@use 'utils' as *;

@mixin material-radio {
  mat-radio-button {
    .mat-radio-outer-circle {
      border-color: app-color('outline');
    }
    &.mat-radio-checked:not(.mat-radio-disabled) {
      .mat-radio-outer-circle {
        border-color: app-color('primary') !important;
      }
      .mat-radio-inner-circle {
        background-color: app-color('primary') !important;
      }
    }
  }
}
