@use '@angular/material' as mat;
@use 'utils' as *;

@mixin snackbar($theme, $alertColor: #de1717) {
  $primary: map-get($theme, primary);

  snack-bar-container.mg-snackbar,
  snack-bar-container.mg-snackbar-alert {
    background-color: mat.get-color-from-palette($primary, 500);
    width: 100vw;
    max-width: $ipadLandscape;
    border-radius: 6px;
    &.mat-snack-bar-container {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }

  snack-bar-container.mg-snackbar-alert {
    background-color: $alertColor;
  }

  .mg-snackbar simple-snack-bar.mat-simple-snackbar {
    color: rgba(255, 255, 255, 0.7);
    justify-content: center;
    // default line-height
    min-height: 20px;
    @include mg-minicopy();
    align-items: center;

    button {
      padding: 8px;
      margin-top: 0px;
      margin-bottom: 0px;
      font-size: 75%;
      color: white;
      border: 1px solid white;
      border-radius: 4px;
      flex-grow: 0;
    }
  }

  .mg-snackbar simple-snack-bar.mat-simple-snackbar,
  .mg-snackbar-alert {
    button {
      @include mg-minicopy();
      padding: 8px;
      margin-top: 0px;
      margin-bottom: 0px;
      font-size: 75%;
      color: white;
      border: 1px solid white;
      border-radius: 4px;
      flex-grow: 0;
      background-color: transparent;
    }
    .no-border {
      button {
        border: none;
      }
    }
  }

  .mg-snackbar-alert .mat-simple-snackbar-action,
  .mg-snackbar .mat-simple-snackbar-action {
    margin-left: 16px;
  }

  .mg-snackbar,
  .mg-snackbar-alert {
    &.mat-snack-bar-container {
      padding-top: 5px;
      padding-bottom: 5px;
      display: flex;
      align-items: center;
    }

    > * {
      width: 100%;
      max-width: $contentMaxWidth -
        (
          2 *
            ($sideToolbarPadding + $sideToolbarWidth + $sideToolbarPaddingRight)
        );
      margin: auto;
    }

    mg-snackbar-icon {
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;

      .mg-snackbar-icon {
        text-align: left;
        align-items: center;
        display: inline-flex;
        margin: auto;
        width: 100%;
        justify-content: space-between;

        mg-button-icon {
          color: inherit;
          --mg-icon-size: 18px;
        }
      }
    }
  }
}
