@use 'utils' as *;

@mixin short-card($theme, $darkTheme, $lightTheme, $lightLinkColor) {
  mg-short-card {
    @include user-content($darkTheme, 0.875);

    .comment-content {
      @include user-content($theme, 0.75);
    }

    &.light-content {
      @include user-content($lightTheme, 0.875, $lightLinkColor);

      .comment-content {
        .date-posted {
          color: darken($lightLinkColor, 15%);
        }
      }
    }
  }
}
