@use 'utils' as *;

@mixin material-tabs-group {
  mat-tab-group {
    .mat-tab-label-active {
      opacity: 1;
    }

    .mat-tab-label {
      flex-basis: var(--mg-mat-tab-label-flex-basis, initial);
      height: var(--mg-mat-tab-label-height, 48px);
    }

    .mat-tab-label-active {
      background-color: var(
        --mg-mat-tab-label-active-background-color,
        initial
      );
    }

    .mat-ink-bar {
      top: var(--mg-mat-tab-ink-bar-top, initial);
      height: var(--mg-mat-tab-ink-bar-height, 2px);
      background-color: var(
        --mg-mat-tab-ink-bar-background-color,
        inherit
      ) !important;
    }
  }

  .mat-tab-nav-bar.disable-pagination,
  mat-tab-group.disable-pagination {
    .mat-tab-header-pagination {
      display: none;
    }
  }
}
