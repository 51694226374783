@use '../settings' as *;
@use '../vendors/sassy-lists' as *;
@use './math' as *;
@use './units' as *;
@use './misc' as *;

$-zf-size: null;

$breakpoints: map-get($app-theme, 'breakpoints');

$breakpoints-hidpi: (
  'hidpi-1': 1,
  'hidpi-1-5': 1.5,
  'hidpi-2': 2,
  'retina': 2,
  'hidpi-3': 3,
) !default;

$print-breakpoint: large !default;

$-zf-zero-breakpoint: small !default;

$-zf-breakpoints-keys: map-keys($breakpoints);

@if nth(map-values($breakpoints), 1) !=0 {
  @error 'The first key in the $breakpoints map must have a value of "0".';
} @else {
  $-zf-zero-breakpoint: nth(map-keys($breakpoints), 1);
}

$breakpoint-classes: (small medium large) !default;

@function breakpoint($val: $-zf-zero-breakpoint) {
  $std-web-dpi: 96;
  $bp: nth($val, 1);
  $bp-next: null;
  $bp-min: null;
  $bp-max: null;
  $dir: if(length($val) > 1, nth($val, 2), up);
  $name: null;
  $hidpi: false;
  @if $bp == 'landscape' or $bp == 'portrait' {
    @return '(orientation: #{$bp})';
  }
  @if type-of($bp) == 'string' {
    @if map-has-key($breakpoints, $bp) {
      $name: $bp;
      $bp: map-get($breakpoints, $name);
      $bp-next: -zf-map-next($breakpoints, $name);
    } @else if map-has-key($breakpoints-hidpi, $bp) {
      $name: $bp;
      $bp: map-get($breakpoints-hidpi, $name);
      $bp-next: -zf-map-next-number($breakpoints-hidpi, $bp);
      $hidpi: true;
    } @else {
      $bp: 0;
      @warn 'breakpoint(): "#{$val}" is not defined in your `$breakpoints` or `$breakpoints-hidpi` setting.';
    }
  }
  @if not $name and $dir == 'only' {
    @warn 'breakpoint(): Only named media queries can have an `only` range.';
    @return null;
  }
  @if $dir == 'only' or $dir == 'up' {
    $bp-min: if($hidpi, strip-unit($bp), zf-bp-to-em($bp));
  }
  @if $dir == 'only' or $dir == 'down' {
    @if not $name {
      $bp-max: if($hidpi, strip-unit($bp), zf-bp-to-em($bp));
    } @else if $bp-next {
      $bp-max: if(
        $hidpi,
        $bp-next - divide(1, $std-web-dpi),
        zf-bp-to-em($bp-next) - 0.00125
      );
    }
  }
  @if $hidpi {
    $bp-min-dpi: if($bp-min, $bp-min * $std-web-dpi * 1dpi, $bp-min);
    $bp-max-dpi: if($bp-max, $bp-max * $std-web-dpi * 1dpi, $bp-max);
    @return zf-str-join(
      -zf-bp-join($bp-min, $bp-max, '-webkit-min-device-pixel-ratio', '-webkit-max-device-pixel-ratio'),
      -zf-bp-join($bp-min-dpi, $bp-max-dpi, 'min-resolution', 'max-resolution'),
      ', '
    );
  } @else {
    @return -zf-bp-join($bp-min, $bp-max);
  }
}

@mixin breakpoint($values...) {
  @for $i from 1 through length($values) {
    $value: nth($values, $i);
    $str: breakpoint($value);
    $bp: index($-zf-breakpoints-keys, nth($value, 1));
    $pbp: index($-zf-breakpoints-keys, $print-breakpoint);
    $dir: if(length($value) > 1, nth($value, 2), up);
    $old-zf-size: null;
    @if global-variable-exists(-zf-size) {
      $old-zf-size: $-zf-size;
    }
    $-zf-size: nth($value, 1) !global;
    @if $str == '' {
      @content;
    } @else {
      @if $bp !=null and ($bp <=$pbp or $dir ==down) {
        @media print,
        screen and #{$str} {
          @content;
        }
      } @else {
        @media screen and #{$str} {
          @content;
        }
      }
    }

    $-zf-size: $old-zf-size !global;
  }
}

@function -zf-bp-serialize($map) {
  $str: '';
  @each $key, $value in $map {
    $str: $str + $key + '=' + zf-bp-to-em($value) + '&';
  }
  $str: str-slice($str, 1, -2);
  @return $str;
}

@function -zf-map-next($map, $key) {
  $values: map-keys($map);
  $i: 0;
  @if (map-has-key($map, $key)) {
    $i: index($values, $key) + 1;
  }
  @if ($i > length($map) or $i ==0) {
    @return null;
  } @else {
    @return map-get($map, nth($values, $i));
  }
}

@function -zf-map-next-number($map, $number) {
  $next-number: null;

  @each $k, $v in $map {
    @if type-of($v) ==
      'number' and
      $v
      >$number and
      ($next-number ==null or $v < $next-number)
    {
      $next-number: $v;
    }
  }

  @return $next-number;
}

@function -zf-breakpoints-less-than($key) {
  $list: ();
  $found-key: false;

  @each $name in $-zf-breakpoints-keys {
    @if ($name ==$key) {
      $found-key: true;
    }

    @if not $found-key {
      $list: append($list, $name);
    }
  }

  @return $list;
}

@function -zf-closest-named-breakpoint($breakpoint) {
  $last: $-zf-zero-breakpoint;
  $found: false;

  $value: unitless-calc($breakpoint, 1px);

  @each $key, $val in $breakpoints {
    @if not $found {
      @if unitless-calc($val) >$value {
        $found: true;
      } @else {
        $last: $key;
      }
    }
  }

  @return $last;
}

@function -zf-get-bp-val($map, $value) {
  @if type-of($map) == 'number' {
    @return $map;
  }
  @if not map-has-key($breakpoints, $value) {
    @if type-of($value) == 'number' {
      $value: -zf-closest-named-breakpoint($value);
    } @else {
      @return null;
    }
  } @else if map-has-key($map, $value) {
    @return map-get($map, $value);
  } @else {
    $anchor: null;
    $found: false;

    @each $key, $val in $breakpoints {
      @if not $found {
        @if map-has-key($map, $key) {
          $anchor: $key;
        }

        @if $key ==$value {
          $found: true;
        }
      }
    }

    @return map-get($map, $anchor);
  }
}

@function -zf-current-breakpoint($value: null, $default: null) {
  @if ($value !=null) {
    @return $value;
  } @else if
    (variable-exists(-zf-size) and type-of($-zf-size) != 'number') and
    $-zf-size
    !=null
  {
    @return $-zf-size;
  } @else {
    @return $default;
  }
}

@function -zf-bp-join(
  $min: 0,
  $max: 0,
  $min-name: 'min-width',
  $max-name: 'max-width'
) {
  @return zf-str-join(
    if($min and $min > 0, '(#{$min-name}: #{$min})', null),
    if($max and $max > 0, '(#{$max-name}: #{$max})', null),
    ' and '
  );
}

$small-up: '';
$small-only: '';

@if map-has-key($breakpoints, small) {
  $small-up: screen;
  $small-only: unquote('screen and #{breakpoint(small only)}');
}

$medium-up: '';
$medium-only: '';

@if map-has-key($breakpoints, medium) {
  $medium-up: unquote('screen and #{breakpoint(medium)}');
  $medium-only: unquote('screen and #{breakpoint(medium only)}');
}

$large-up: '';
$large-only: '';

@if map-has-key($breakpoints, large) {
  $large-up: unquote('screen and #{breakpoint(large)}');
  $large-only: unquote('screen and #{breakpoint(large only)}');
}

$xlarge-up: '';
$xlarge-only: '';

@if map-has-key($breakpoints, xlarge) {
  $xlarge-up: unquote('screen and #{breakpoint(xlarge)}');
  $xlarge-only: unquote('screen and #{breakpoint(xlarge only)}');
}

$xxlarge-up: '';

@if map-has-key($breakpoints, xxlarge) {
  $xxlarge-up: unquote('screen and #{breakpoint(xxlarge)}');
}
