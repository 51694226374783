@use '@angular/material' as mat;
@use 'utils' as *;

@mixin calendar(
  $theme,
  $dayColor: $md-black,
  $pastColor: $md-disabled,
  $checkInColor: $md-mingablue
) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  mg-calendar {
    .mat-calendar-content {
      padding-bottom: 0;
    }
    .mat-calendar-previous-button,
    .mat-calendar-next-button,
    .mg-calender-body-row {
      transition: opacity bezierTransition();
      opacity: 1;
    }
    .weekView {
      .mat-calendar-previous-button:not(.toggle),
      .mat-calendar-next-button {
        opacity: 0;
        pointer-events: none;
      }
      .mg-calender-body-row:not(.has-selected) {
        opacity: 0;
      }

      .calendar-collapse-toggle {
        button.toggle {
          &::after {
            transform: translateX(2px) rotate(225deg);
          }
        }
      }
    }
    .weekViewHideAll {
      .mg-calender-body-row:not(.has-selected) {
        display: none;
      }
    }
    .mat-calendar-controls {
      margin: 0 0 0.2em 0;
    }

    .mat-calendar-period-button {
      .mat-button-focus-overlay {
        background-color: transparent !important;
      }
    }

    .calendar-collapse-toggle {
      position: relative;
      text-align: center;
      height: 25px;

      .mat-ripple-element {
        background: linear-gradient(
          rgba(255, 255, 255, 0),
          mat.get-color-from-palette($pastColor, 200)
        );
        opacity: 0.5;
      }

      .calendar-collapse-toggle-bg,
      mg-icon {
        transition: opacity bezierTransition();
      }

      button.toggle {
        position: absolute;
        top: 50%;
        left: -9px;
        right: 0;
        margin: auto;
        transform: translateY(-50%);

        &::after {
          transition: opacity bezierTransition(), transform bezierTransition();
          transform: translateX(2px) rotate(45deg);
        }
      }

      .calendar-collapse-toggle-bg {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 0;
        background: linear-gradient(
          rgba(255, 255, 255, 0),
          mat.get-color-from-palette($pastColor, 200)
        );
        opacity: 0;
      }

      @keyframes gradientFade {
        0% {
          opacity: 1;
        }

        100% {
          opacity: 0;
        }
      }
      &:hover {
        cursor: pointer;

        @media not all and (hover: none) {
          .calendar-collapse-toggle-bg {
            opacity: 1;
          }
          mg-icon,
          button.toggle {
            opacity: 0.75;
          }
        }
      }
      &:focus,
      &:active {
        .calendar-collapse-toggle-bg {
          animation: 3s linear gradientFade;
          opacity: 0;
        }
      }
    }

    .mat-calendar-body-cell-content {
      width: 80%;
      height: 80%;
      border: none;
      text-align: center;

      .body-cell-content-value,
      .body-cell-content-bg {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right: 0;
        margin: auto;
      }

      .body-cell-content-value {
        z-index: 1;
      }

      .body-cell-content-bg {
        border-width: 1px;
        border-style: solid;
        border-radius: 999px;
        border-color: transparent;
        padding: 0;
        @include responsiveScaling(23px, 25px, height, $iphone6plusWidth);
        @include responsiveScaling(23px, 25px, width, $iphone6plusWidth);
        z-index: 0;
        box-sizing: border-box;

        transition: background-color bezierTransition(),
          border-color bezierTransition();
      }

      &.selected {
        .body-cell-content-value {
          color: white;
        }
        .body-cell-content-bg {
          border-width: 1px;
          border-style: solid;
          border-radius: 999px;
          border-width: 2px;
          background-color: mat.get-color-from-palette($primary, '600');
        }

        &:hover {
          opacity: 0.75;
        }
      }
      &.filtering {
        .body-cell-content-bg {
          border-width: 1.5px;
        }
      }
      &.going {
        .body-cell-content-bg {
          background-color: mat.get-color-from-palette($accent);
        }
      }
      &.checked-in {
        .body-cell-content-bg {
          background-color: mat.get-color-from-palette($checkInColor, 500);
        }
      }

      &.past {
        &:not(.selected) {
          // color: rgba(0, 0, 0, 0.5);
          color: mat.get-color-from-palette($pastColor, 600);
        }

        &.selected {
          .body-cell-content-bg {
            background-color: mat.get-color-from-palette($primary, 100);
          }
        }
        &.going {
          .body-cell-content-bg {
            background-color: mat.get-color-from-palette($accent, 100);
          }
        }
        &.checked-in {
          .body-cell-content-bg {
            background-color: mat.get-color-from-palette($checkInColor, 100);
          }
        }
      }

      &.mat-calendar-body-today,
      &.filtering {
        .body-cell-content-bg {
          // default border-color
          border-color: rgba(0, 0, 0, 0.38);
        }
        .body-cell-content-value {
          // default mat-calendar color
          // color: rgba(0, 0, 0, 0.87);
          color: mat.get-color-from-palette($dayColor, 500);
        }

        &.selected {
          .body-cell-content-bg {
            border-color: mat.get-color-from-palette($primary, '600');
            background-color: transparent;
          }
        }

        &.going {
          .body-cell-content-bg {
            background-color: transparent;
            border-color: mat.get-color-from-palette($accent);
          }
        }
        &.checked-in {
          .body-cell-content-bg {
            background-color: transparent;
            border-color: mat.get-color-from-palette($checkInColor, 500);
          }
        }
      }
    }
    .mat-calendar-body-cell {
      @include mg-minicopy();
      line-height: 1;

      &:hover {
        .mat-calendar-body-cell-content {
          background-color: transparent !important;

          .body-cell-content-bg {
            background-color: rgba(0, 0, 0, 0.04);
          }
        }
        .mat-calendar-body-cell-content.selected:not(.mat-calendar-body-today):not(.filtering) {
          opacity: 0.75;

          .body-cell-content-bg {
            background-color: mat.get-color-from-palette($primary, '600');
          }

          &.going {
            .body-cell-content-bg {
              background-color: mat.get-color-from-palette($accent);
            }
          }
          &.checked-in {
            .body-cell-content-bg {
              background-color: mat.get-color-from-palette($checkInColor, 500);
            }
          }
        }
      }
    }

    .mat-calendar-controls {
      justify-content: space-between;
    }
    .mat-calendar-table-header {
      @include mg-minicopy(0.833);

      > tr > th {
        &:first-child,
        &:last-child {
          // default color
          color: rgba(0, 0, 0, 0.38);
        }
        color: mat.get-color-from-palette($primary, '600');
      }
    }
    .mat-calendar-period-button {
      @include mg-minicopy();
      font-weight: 700;
    }
    .mat-calendar-table-header,
    .mat-calendar-period-button {
      text-transform: uppercase;
    }
    .mat-calendar-table-header-divider {
      display: none;
    }
  }

  .mat-calendar {
    &.upcoming,
    &.past,
    &.going {
      .mat-calendar-body-cell-content:not(.selected):not(.mat-calendar-body-today):not(.filtering) {
        .body-cell-content-bg {
          border: none;
        }
      }
    }
    &.upcoming {
      .mat-calendar-body-cell-content.past {
        .body-cell-content-value {
          color: mat.get-color-from-palette($pastColor, 600);
        }
        .body-cell-content-bg {
          background-color: transparent;
        }
        &.selected {
          .body-cell-content-bg {
            background-color: transparent;
          }
        }
      }
      .mat-calendar-body-cell.past:hover {
        cursor: default !important;

        .mat-calendar-body-cell-content {
          .body-cell-content-value {
            color: mat.get-color-from-palette($pastColor, 600);
          }
          .body-cell-content-bg {
            background-color: transparent !important;
          }
        }
      }
    }
    &.past {
      .mat-calendar-body-cell-content:not(.past) {
        .body-cell-content-bg {
          background-color: transparent;
        }
        .body-cell-content-value {
          // default mat-calendar color
          // color: rgba(0, 0, 0, 0.87);
          color: mat.get-color-from-palette($dayColor, 500);
        }
      }
      .mat-calendar-body-cell:hover:not(.past):not(.today) {
        cursor: default !important;

        .mat-calendar-body-cell-content:not(.past) {
          opacity: 1 !important;
          .body-cell-content-bg {
            background-color: transparent !important;
          }
        }
      }
    }
    &.going {
      .mat-calendar-body-cell-content:not(.going) {
        .body-cell-content-value {
          // default mat-calendar color
          // color: rgba(0, 0, 0, 0.87);
          color: mat.get-color-from-palette($dayColor, 500);
        }
        .body-cell-content-bg {
          background-color: transparent;
        }
        &.past {
          .body-cell-content-value {
            color: mat.get-color-from-palette($pastColor, 600);
          }
        }
        &.selected {
          .body-cell-content-bg {
            // border-color: mat-color($primary, '600');
            background-color: transparent;
          }
        }
      }
    }
  }
}
