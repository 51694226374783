@use 'utils' as *;

@mixin material-slide-toggle {
  mat-slide-toggle:not(.mat-disabled) {
    .mat-ripple {
      display: none;
    }

    .mat-slide-toggle-bar {
      background-color: app-color('outline');
    }

    .mat-slide-toggle-thumb {
      background-color: map-get($grey, '20');

      &:after {
        content: '';
        position: absolute;
        inset: 0;
        width: 150%;
        height: 150%;
        background-color: map-get($tertiary, '600');
        border-radius: 50%;
        opacity: 0;
        transform: translate(-17%, -17%);
      }

      &:hover {
        &:after {
          opacity: 0.08;
        }

        &:focus {
          opacity: 0.12;
        }
      }
    }

    &.mat-checked {
      .mat-slide-toggle-bar {
        background-color: map-get($primary, '100') !important;
      }
      .mat-slide-toggle-thumb {
        background-color: app-color('primary') !important;
      }
    }
  }

  mat-slide-toggle.mat-disabled {
    opacity: 1 !important;
    .mat-slide-toggle-bar {
      background-color: app-color('disabled-bg') !important;
    }
    .mat-slide-toggle-thumb {
      background-color: #fff !important;
    }
  }
}
