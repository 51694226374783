@use 'utils' as *;

@mixin material-datepicker {
  .mat-calendar-content {
    .mat-calendar-body-cell-content {
      color: app-color('surface');
      &.mat-calendar-body-selected {
        background-color: app-color('primary');
        color: #fff;
        &.mat-calendar-body-today {
          box-shadow: none !important;
          background-color: #fff;
          color: app-color('surface');
          border: 1px solid app-color(primary);
        }
      }
    }

    // hides the month since it's redundant, but still take up the space
    .mat-calendar-body-label {
      opacity: 0;

      // if the month label takes the whole row we can remove all together
      &[colspan='7'] {
        display: none;
      }
    }
    .mat-calendar-body-in-range::before {
      background: map-get($primary, '50');
    }
  }
}
