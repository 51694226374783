@use '@angular/material' as mat;
@use 'utils' as *;

@mixin material-accordian {
  .mat-expansion-panel {
    color: currentColor;
    box-shadow: none !important;

    &.bordered {
      border-top: 1px solid mat.get-color-from-palette($md-compgrey, 500);
      border-bottom: 1px solid mat.get-color-from-palette($md-compgrey, 500);

      & + .mat-expansion-panel {
        border-top: none;
      }
    }

    &.mat-expansion-panel-spacing {
      margin-top: 0;
    }

    .mat-expansion-panel-header,
    .mat-expansion-panel-header:not([aria-disabled='true']) {
      &:hover {
        background-color: initial !important;
      }
    }

    .mat-expansion-panel-header-title {
      color: currentColor;
    }

    .mat-expansion-panel-content {
      font-size: inherit;
    }

    .mat-expansion-panel-body {
      // default to material's default padding, provide var to avoid ::ng-deep
      padding: var(--mg-mat-expansion-panel-body-padding, 0 24px 16px);
    }
  }
}
