@use '@angular/material' as mat;
@use './palettes' as *;

$global-font-size: 16px;
$global-text-direction: ltr;

/**
* Centralize the more common z-index values we need to be aware of app wide
* Avoid adding values with consecutive numbers eg 1000, 1001
*/
$zindex-modal: 1000;
// we probably want this to be highest
$zindex-loader-overlay: 1100;

$app-theme: (
  // ******** any updates to these colors should also be reflected in the types for this `app/src/app/shared/constants/mingaColors.ts` ********
  color:
    (
      primary: map-get($primary, '600'),
      secondary: map-get($secondary, '600'),
      tertiary: map-get($tertiary, '600'),
      // Grayscale
      transparent: 'transparent',
      white: #f5f5f5,
      black: map-get($grey, '950'),
      // Surface
      surface: map-get($primary, '900'),
      surface-alt: #5e779c,
      // Outline
      outline: map-get($grey, '500'),
      outline-alt: map-get($grey, '100'),
      // Disabled
      disabled-bg: map-get($grey, '50'),
      on-disabled-bg: map-get($grey, '300'),
      // Error
      error: map-get($error, '500'),
      error-bg: map-get($error, '50'),
      on-error-bg: map-get($error, '700'),
      // Warning
      warn: map-get($warning, '600'),
      warn-bg: map-get($warning, '50'),
      on-warn-bg: map-get($warning, '800'),
      // Success
      success: map-get($success, '600'),
      success-bg: map-get($success, '50'),
      on-success-bg: map-get($success, '800'),
      // used for cards/surface grey bgs (bluer gray outside our grayscale) that's why we need seperate grey config
      surface-card: map-get($secondary, '50'),
      surface-card-high: map-get($secondary, '100'),
      surface-card-highest: map-get($secondary, '200'),
      // Module Colors
      hall-pass: #e67aa0,
      behavior: #759e90,
      community: #e2ab56,
      flextime: map-get($success, '600'),
      check-in: #0097ad,
      minga-manager: #2b55c3,
      id-manager: #9365b2,
      behavior-praise: '#23ad98',
      behavior-consequence: '#df6565',
    ),
  fonts: (
    body: (
      'Muli',
      sans-serif,
    ),
    heading: (
      'Muli',
      sans-serif,
    ),
    mono: (
      'Noto Sans',
      sans-serif,
    ),
  ),
  // Matches https://tburleson-layouts-demos.firebaseapp.com/#/responsive
  breakpoints:
    (
      'xsmall': 0,
      'small': 600px,
      'medium': 960px,
      'medium-large': 1100px,
      'large': 1280px,
      'xlarge': 1920px,
    ),
  fontWeights: (
    normal: 400,
    bold: 700,
    black: 900,
  ),
  spacing: (
    '0': 0px,
    '0-half': 2px,
    '1': 4px,
    '1-half': 6px,
    '2': 8px,
    '2-half': 10px,
    '3': 12px,
    '3-half': 14px,
    '4': 16px,
    '5': 20px,
    '6': 24px,
    '7': 28px,
    '8': 32px,
    '9': 36px,
    '10': 40px,
    '11': 44px,
    '12': 48px,
    '14': 56px,
    '16': 64px,
    '20': 80px,
  ),
  elevation: (
    1: (
      '0px 1px 2px rgba(0, 0, 0, 0.15)',
      '0px 2px 2px rgba(0, 0, 0, 0.2)',
    ),
    2: (
      '0px 2px 6px rgba(0, 0, 0, 0.15)',
      '0px 1px 2px rgba(0, 0, 0, 0.3)',
    ),
    3: (
      '0px 1px 2px rgba(0, 0, 0, 0.3)',
      '0px 4px 4px rgba(0, 0, 0, 0.25)',
    ),
    4: (
      '0px 1px 3px rgba(0, 0, 0, 0.3)',
      '0px 4px 8px rgba(0, 0, 0, 0.15)',
    ),
  ),
  zIndices: (
    hide: -1,
    auto: 'auto',
    base: 0,
  ),
  radii: (
    none: 0px,
    xsmall: 2px,
    small: 5px,
    medium: 8px,
    large: 10px,
    xlarge: 20px,
    full: 9999px,
  ),
  // width utils, taken from tailwind https://tailwindcss.com/docs/width
  // types are defined here app/src/app/shared/utils/width.ts
  width:
    (
      w-0: 0px,
      w-px: 1px,
      w-0-half: 0.125rem,
      w-1: 0.25rem,
      w-1-half: 0.375rem,
      w-2: 0.5rem,
      w-2-half: 0.625rem,
      w-3: 0.75rem,
      w-3-half: 0.875rem,
      w-4: 1rem,
      w-5: 1.25rem,
      w-6: 1.5rem,
      w-7: 1.75rem,
      w-8: 2rem,
      w-9: 2.25rem,
      w-10: 2.5rem,
      w-11: 2.75rem,
      w-12: 3rem,
      w-14: 3.5rem,
      w-16: 4rem,
      w-18: 4.5rem,
      w-20: 5rem,
      w-24: 6rem,
      w-28: 7rem,
      w-32: 8rem,
      w-36: 9rem,
      w-40: 10rem,
      w-44: 11rem,
      w-48: 12rem,
      w-52: 13rem,
      w-56: 14rem,
      w-60: 15rem,
      w-64: 16rem,
      w-72: 18rem,
      w-80: 20rem,
      w-96: 24rem,
      w-auto: auto,
      w-1-2: 50%,
      w-1-3: 33.333333%,
      w-2-3: 66.666667%,
      w-1-4: 25%,
      w-2-4: 50%,
      w-3-4: 75%,
      w-1-5: 20%,
      w-2-5: 40%,
      w-3-5: 60%,
      w-4-5: 80%,
      w-1-6: 16.666667%,
      w-2-6: 33.333333%,
      w-3-6: 50%,
      w-4-6: 66.666667%,
      w-5-6: 83.333333%,
      w-1-12: 8.333333%,
      w-2-12: 16.666667%,
      w-3-12: 25%,
      w-4-12: 33.333333%,
      w-5-12: 41.666667%,
      w-6-12: 50%,
      w-7-12: 58.333333%,
      w-8-12: 66.666667%,
      w-9-12: 75%,
      w-10-12: 83.333333%,
      w-11-12: 91.666667%,
      w-full: 100%,
      w-screen: 100vw,
      w-min: min-content,
      w-max: max-content,
      w-fit: fit-content,
    )
);

// Sizes
$iphone5Width: 320px;
$iphone6Width: 375px;
$iphone6plusWidth: 414px;
$ipadPortrait: 768px;
$ipadLandscape: 1024px;

$contentMaxWidth: 1014px;
$sideToolbarPadding: 16px;
$feedItemsMobileMaxWidth: 510px;
$feedItemsHorizontalPadding: 16px;
$feedItemsVerticalPadding: 16px;
$feedItemsMarginBottom: 32px;
$feedItemsMobileMarginWidth: 482px;

$fxLayout_xs: 599px;
$fxLayout_sm: 959px;
$fxLayout_med: 1280px;

$sideToolbarPaddingRight: 16px;
$sideToolbarWidth: 155px;
$toolbarHeight: 50px;

$overlayContentHPaddingMobile: 16px;
$overlayContentVPaddingMobile: 24px;
$overlayContentHPaddingDesktop: 32px;
$overlayContentVPaddingDesktop: 32px;
$overlayVerticalPadding: 64px;
$overlayDefaultBorderRadius: 6px;
$overlayBorderRadius: 20px;
$navLeftWidth: $sideToolbarWidth + $sideToolbarPadding +
  $sideToolbarPaddingRight;

// @NOTE: If these are changed please update `app/src/app/overlay/utility.ts`
$overlayMaxWidth: 740px;
$overlayWideMaxWidth: 1140px;

// Margin on edge of screen when overlay is close to max width
$overlayMaxWidthMargin: 32px;
$mingaColorsHeight: 4px;

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
$primary-typography: mat.define-typography-config(
  $font-family: 'Muli, sans-serif',
  // mg-headline (h1)
  $headline: mat.define-typography-level(19px, 19px, 400),
  // mg-bodycopy (h2)
  $title: mat.define-typography-level(16px, 16px, 400),
  // mg-bodycopy (h3)
  $subheading-2: mat.define-typography-level(16px, 16px, 400),
  // mg-bodycopy (h4)
  $subheading-1: mat.define-typography-level(16px, 16px, 400),
  // mg-bodycopy (p/body text)
  $body-1: mat.define-typography-level(16px, 16px, 400),
  // mg-bodycopy-bold (p/body bold text)
  $body-2: mat.define-typography-level(16px, 16px, 700),
  // mg-minicopy
  $caption: mat.define-typography-level(10px, 10px, 400),
  // mg-bodycopy (p/body text)
  $button: mat.define-typography-level(16px, 16px, 400),
  // Line-height must be unit-less fraction of the font-size.
  $input: mat.define-typography-level(inherit, 1.2, 400),
);

// @TODO: update this with responsive or more media query breakpoints...
@media (min-width: #{$ipadPortrait}) {
  $tablet-typography: mat.define-typography-config(
    $font-family: 'Muli, sans-serif',
    // mg-headline (h1)
    $headline: mat.define-typography-level(20px, 24px, 400),
    // mg-bodycopy (h2)
    $title: mat.define-typography-level(17px, 20.4px, 400),
    // mg-bodycopy (h3)
    $subheading-2: mat.define-typography-level(17px, 20.4px, 400),
    // mg-bodycopy (h4)
    $subheading-1: mat.define-typography-level(17px, 20.4px, 400),
    // mg-bodycopy (p/body text)
    $body-1: mat.define-typography-level(17px, 20.4px, 400),
    // mg-bodycopy-bold (p/body bold text)
    $body-2: mat.define-typography-level(17px, 20.4px, 700),
    // mg-minicopy
    $caption: mat.define-typography-level(13px, 15.6px, 400),
    // mg-bodycopy (p/body text)
    $button: mat.define-typography-level(17px, 20.4px, 400),
    // Line-height must be unit-less fraction of the font-size.
    $input: mat.define-typography-level(inherit, 1.2, 400),
  );
  @include mat.all-component-typographies($tablet-typography);
}

$label-typography: mat.define-typography-config(
  $font-family: 'Muli, sans-serif',
  // mg-bodycopy (p/body text)
  $body-1: mat.define-typography-level(16px, 24px, 400),
  // mg-bodycopy-bold (p/body bold text)
  $body-2: mat.define-typography-level(16px, 24px, 700),
  // mg-bodycopy (p/body text)
  $button: mat.define-typography-level(16px, 24px, 400),
  // Line-height must be unit-less fraction of the font-size.
  $input: mat.define-typography-level(inherit, 1.125, 400),
);
@include mat.checkbox-typography($label-typography);

$minga-app-warn: mat.define-palette($md-warning);

// Primary Blue Compontent Theme
$minga-app-primary: mat.define-palette($md-mingablue);
$minga-app-accent: mat.define-palette($md-purple);
$minga-app-blue-theme: mat.define-light-theme(
  $minga-app-primary,
  $minga-app-accent,
  $minga-app-warn
);

// Primary Purple Compontent Theme
$minga-app-purple-primary: mat.define-palette($md-purple);
$minga-app-purple-accent: mat.define-palette($md-mingablue);
$minga-app-purple-theme: mat.define-light-theme(
  $minga-app-purple-primary,
  $minga-app-purple-accent,
  $minga-app-warn
);

// Form/Button Components Theme
$minga-app-form-primary: mat.define-palette($md-navyblue);
$minga-app-form-accent: mat.define-palette($md-mingablue);
$minga-app-form-warm: mat.define-palette($md-warning);
$minga-app-black: mat.define-palette($md-black);
$minga-app-text: mat.define-palette($md-cardgrey);
$minga-app-form-theme: mat.define-light-theme(
  $minga-app-form-primary,
  $minga-app-form-accent,
  $minga-app-warn
);
$minga-app-grey-form-theme: mat.define-light-theme(
  $minga-app-text,
  $minga-app-purple-primary,
  $minga-app-warn
);
$minga-app-button-theme: mat.define-light-theme(
  $minga-app-form-primary,
  $minga-app-accent,
  $minga-app-warn
);
$minga-app-form-dark-theme: mat.define-light-theme(
  $minga-app-black,
  $minga-app-form-primary,
  $minga-app-warn
);
$minga-app-content-theme: mat.define-light-theme(
  $minga-app-form-primary,
  $minga-app-black,
  $minga-app-warn
);
$minga-app-content-dark-theme: mat.define-light-theme(
  $minga-app-black,
  $minga-app-form-primary,
  $minga-app-warn
);

// Disabled Theme
$minga-app-disabled-primary: mat.define-palette($md-disabled);
$minga-app-disabled-accent: mat.define-palette($md-disabled);
$minga-app-disabled-warm: mat.define-palette($md-warning);
$minga-app-disabled-theme: mat.define-light-theme(
  $minga-app-disabled-primary,
  $minga-app-disabled-accent,
  $minga-app-warn
);

// Misc
$minga-app-white: mat.define-palette($md-white);
$minga-app-progress-spinner-theme: mat.define-light-theme(
  $minga-app-white,
  $minga-app-primary,
  $minga-app-purple-primary
);
$minga-app-calendar-theme: mat.define-light-theme(
  $minga-app-form-primary,
  $minga-app-purple-primary,
  $minga-app-white
);
$minga-app-grey-theme: mat.define-light-theme(
  mat.define-palette($md-compgrey),
  mat.define-palette($md-compgrey),
  mat.define-palette($md-compgrey)
);

$minga-page-theme: mat.define-light-theme(
  mat.define-palette($md-cardgrey),
  $minga-app-primary,
  $minga-app-accent
);

// Create the theme object (a Sass map containing all of the palettes).
$minga-white-theme: mat.define-light-theme($minga-app-white, $minga-app-white);

$header-background-colors: (
  'blue': #2b55c3,
  'green': #91bcad,
  'navy': #003366,
  'orange': #daac64,
  'pink': #e67aa0,
  'teal': #1d9fb9,
  'people-blue': $peoplePrimary,
  'purple': $idPrimary,
  'alt-teal': $flexPrimary,
  'success': #4ba083,
  'error': #d04727,
  'warning': #de8832,
);

$overlay-backdrop-options: (
  'black': rgba(0, 0, 0, 0.75),
  'orange': rgba(226, 171, 86, 0.7),
  'dark-blue': rgba(#1c2f59, 0.93),
  'lime': rgba(214, 226, 97, 0.6),
);

$_minga-form-disabled-color: #c9c9c9;
$_minga-form-active-color: #4ba0cb;

$minga-input-form-vars: (
  'size': (
    'height': rem-calc(50),
  ),
  'padding': (
    'vertical': 0,
    'horizontal': rem-calc(10),
  ),
  'font': (
    'size': rem-calc(16),
  ),
  'textColor': (
    'placeholder': #000,
    'default': #c9c9c9,
    'disabled': #c9c9c9,
    'active': #c9c9c9,
    'focus': #000,
    'hover': #000,
    'valid': #4ba083,
    'error': #d17d69,
  ),
  'borderColor': (
    'default': #4ba083,
    'disabled': #c9c9c9,
    'active': $_minga-form-active-color,
    'focus': $_minga-form-active-color,
    'hover': #4ba083,
    'valid': #4ba083,
    'error': #d17d69,
  ),
  'borderRadius': (
    'default': rem-calc(5),
  ),
);
