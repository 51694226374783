@use 'utils' as *;

@mixin material-chip {
  mat-chip-list.single-line {
    div.mat-chip-list-wrapper {
      flex-wrap: nowrap;
      overflow-x: auto;
      white-space: nowrap;
      margin-left: 0;
      margin-right: 0;

      .mat-chip {
        flex: 0 0 auto;
      }
    }
  }
}
