@use 'utils' as *;

@mixin material-button-toggle-group {
  mat-button-toggle-group.filter-toggle-group {
    border: none;
    overflow: visible;

    .mat-ripple {
      display: none;
    }

    @include breakpoint(small down) {
      width: 100%;
    }

    &.always-full-width {
      width: 100%;
      mat-button-toggle {
        flex: 1;
      }
    }

    mat-button-toggle {
      font-size: rem-calc(16);
      padding: spacing('2') spacing('1');
      border: 1px solid app-color('outline') !important;

      &:first-child {
        border-radius: rem-calc(5) 0 0 rem-calc(5);
      }
      &:last-child {
        border-radius: 0 rem-calc(5) rem-calc(5) 0;
      }
      &:only-child {
        border-radius: rem-calc(5);
      }
      &:not(:first-child) {
        border-left: none !important;
      }

      &.mat-button-toggle-checked,
      &.active {
        background-color: map-get($primary, '50') !important;
      }

      &:after {
        content: '';
        position: absolute;
        background-color: map-get($tertiary, '600');
        opacity: 0;
      }

      &:hover {
        &:after {
          opacity: 0.08;
        }

        &:focus {
          opacity: 0.12;
        }
      }

      @include breakpoint(small down) {
        flex: 1;
      }
    }
  }
}
