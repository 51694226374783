@use '@angular/material' as mat;
@use 'utils' as *;

@mixin default-input($theme, $comp-inactive, $text-inactive) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  input {
    color: mat.get-color-from-palette($primary, '600');
  }
  .active {
    color: mat.get-color-from-palette($primary, '600');
  }

  .mat-input-placeholder {
    color: $text-inactive;
  }

  mat-icon {
    color: $text-inactive;
  }

  mat-form-field {
    .mat-form-field-underline {
      background-color: $comp-inactive;
    }

    &.mat-focused {
      mat-icon {
        color: mat.get-color-from-palette($primary, '600');
      }

      &:not(.mat-form-field-invalid) {
        .mat-form-field-underline {
          .mat-form-field-ripple {
            background-color: mat.get-color-from-palette($primary, 500);
          }
        }
      }
    }
  }
}

@mixin input($theme, $white, $error) {
  $accent: map-get($theme, accent);
  $primary: map-get($theme, primary);
  $disabled: mat.get-color-from-palette($md-disabled, 500);

  .mat-hint {
    @include mg-minicopy(0.83);
  }

  mat-form-field {
    width: 100%;

    mat-hint {
      transform: translateY(-120%);
      transition: transform bezierTransition();
    }

    mat-icon {
      vertical-align: bottom;
    }

    // placeholder text color
    .mat-form-field-label {
      color: mat.get-color-from-palette($primary, 500);
    }

    .mat-form-field-underline {
      background-color: mat.get-color-from-palette($primary, 500);
    }

    &.hidden-required {
      .mat-placeholder-required {
        display: none;
      }
    }

    .mat-form-field-hint-wrapper,
    mat-error {
      @include mg-minicopy(0.83);
    }

    .clickable {
      &:hover {
        cursor: pointer;
      }
    }

    &.mat-focused {
      mat-hint {
        transform: translateY(0%);
      }

      &:not(.mat-form-field-invalid) {
        .mat-form-field-underline {
          .mat-form-field-ripple {
            // background-color: app-color('primary')
            background-color: mat.get-color-from-palette($accent, 500);
          }
        }
      }
    }

    &.mat-form-field-disabled {
      mat-icon {
        color: $disabled;
      }
    }
  }

  .mg-file-upload {
    .file-upload-overlay {
      background-color: transparentize(mat.get-color-from-palette($primary, 500), 0.5);
      color: white;
    }
    .file-upload-errorlay {
      background-color: transparentize(mat.get-color-from-palette($white, 500), 0.25);
      color: mat.get-color-from-palette($error, 500);

      mat-icon {
        margin: auto;
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        text-align: center;
        font-size: 225%;
        height: auto;
      }
    }
  }
}
