@mixin reset-button {
  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;
  border-radius: 0;

  text-align: inherit;
  box-shadow: none;
  color: inherit;
  font: inherit;
  padding: 0;
  margin: 0;
  background: none;
  border: none;
  outline: none;
  box-sizing: border-box;
  cursor: pointer;
}

@mixin reset-list {
  padding: 0;
  margin: 0;
  list-style: none;
  list-style-type: none;
}
