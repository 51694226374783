@use '../settings/palettes' as *;
@use '../settings' as *;
@use 'text' as *;

$linkColor: #9933ff;
$lightLinkColor: #ffffff;

@mixin user-content($theme, $multiplier: 1, $linkColor: #9933ff) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  // Default text styles in user-content
  @include mg-bodycopy($multiplier);
  color: app-color('surface');

  h1,
  h2,
  h3,
  h4,
  h6 {
    color: app-color('surface');
    margin: 1em 0em;
  }

  h1 {
    @include mg-headline($multiplier);
    line-height: 1.25;
  }

  h2 {
    @include mg-headline2($multiplier);
    line-height: 1.2;
  }

  h3 {
    @include mg-headline3($multiplier);
  }

  h4 {
    @include mg-headline4($multiplier);
  }

  h5 {
    @include mg-headline5($multiplier);
  }

  h6 {
    @include mg-headline6($multiplier);
  }

  h1,
  h2,
  h3,
  p {
    margin: 1em 0 0.5em 0;
  }

  h4,
  h5,
  h6 {
    margin: 1em 0 0 0;
  }

  p,
  ul,
  ol,
  blockquote {
    @include mg-bodycopy($multiplier);
    color: app-color('primary');
  }

  a {
    @include mg-bodycopy($multiplier);
    color: $linkColor;
    cursor: pointer;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    &.disabled {
      color: grayscale($linkColor);
      cursor: default;
      pointer-events: none;
    }
  }

  h1:last-child,
  h2:last-child,
  h3:last-child,
  h4:last-child,
  h5:last-child,
  h6:last-child,
  p:last-child {
    margin: 1em 0em;
  }

  h1 + p,
  h2 + p {
    margin: 0.5em 0;
  }

  h3 + p {
    margin: -0.25em 0 0.5em 0;
  }

  h4 + p,
  h5 + p,
  h6 + p {
    margin: 0em 0 0.5em 0;
  }

  h4 + p:last-child,
  h5 + p:last-child,
  h6 + p:last-child {
    margin: 0em 0 1em 0;
  }

  blockquote {
    border-left: 4px solid app-color('primary');
    margin-left: 0;
    padding-left: 1em;
    margin: 1em 0em;
    margin-left: 1em;
    font-style: italic;
  }

  .body-content,
  .body-contents {
    color: app-color('primary');
  }
}
