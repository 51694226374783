@use 'utils' as *;

@mixin material-cdk-overlays {
  .cdk-overlay-pane.full {
    width: 100vw;
    height: 100vh;
    max-width: 100vw !important;
  }

  .cdk-overlay-pane.fade {
    animation: none !important;
    transition: none !important;

    mat-dialog-container {
      animation: none !important;
      transition: none !important;
    }
  }

  .cdk-overlay-backdrop {
    &.mg-white-backdrop {
      background-color: rgba(255, 255, 255, 0.92);
    }

    &.mg-dialog-backdrop-blue {
      background-color: rgba($policyLinkColor, 0.92);
    }

    &.mg-dialog-backdrop-darkblue {
      background-color: rgba($mingaBlue1, 0.92);
    }

    &.mg-dialog-backdrop-orange {
      background-color: rgba($checkinAccent, 0.92);
    }

    &.mat-datepicker-0-backdrop {
      @include breakpoint(xsmall down) {
        background-color: rgba($mingaBlue1, 0.8);
      }
    }
  }

  .mg-dialog-large.cdk-overlay-pane {
    min-width: 100vw;
    min-height: 100vh;
    max-width: none !important;

    @media (max-width: $fxLayout_xs) {
      .mat-dialog-container {
        border-radius: 0px !important;
        display: flex;
        flex-direction: column;
      }
    }

    @media (min-width: $fxLayout_xs + 1px) {
      min-width: $iphone6plusWidth;
    }

    @media (min-width: $fxLayout_xs + 1px) and (min-height: $fxLayout_xs) {
      min-height: $fxLayout_xs;
    }

    .mat-dialog-content,
    .mat-dialog-container {
      padding: 0;
      margin: 0;
    }
  }

  .mg-overflow-dialog {
    .mat-dialog-container {
      overflow: visible;
      width: 75vw;
      max-width: 400px;
    }
  }

  .cdk-overlay-pane {
    &.mg-above-bottomnav {
      bottom: $toolbarHeight !important;
    }
  }

  .reset-mat-dialog-padding {
    mat-dialog-container {
      padding: 0;
    }
  }

  .cdk-overlay-container,
  .cdk-global-overlay-wrapper,
  .cdk-overlay-backdrop,
  .cdk-overlay-pane {
    z-index: $zindex-modal;
  }

  .mg-normal-padding-dialog .mat-dialog-container {
    $normalPadding: 16px;
    padding: $normalPadding;

    .mat-dialog-content {
      padding: 0 $normalPadding;
      margin: 0 #{-$normalPadding};
    }
  }

  .mg-bordered-dialog.cdk-overlay-pane {
    min-height: 500px;
    min-width: 345px;
  }

  .mg-bordered-dialog.cdk-overlay-pane > .mat-dialog-container {
    border-radius: 15px;
    overflow: unset;
    display: flex;
    flex-direction: column;
  }

  .mg-light-border-dialog.cdk-overlay-pane > .mat-dialog-container {
    border-radius: $borderRadius;
  }

  .mg-rounded-dialog.cdk-overlay-pane > .mat-dialog-container {
    border-radius: 15px;
  }

  .mg-no-padding-dialog.cdk-overlay-pane > .mat-dialog-container {
    padding: 0;
  }

  .mg-filled-dialog.cdk-overlay-pane > .mat-dialog-container {
    justify-content: stretch;

    > * {
      flex-grow: 1;
    }
  }
}
