@use '@angular/material' as mat;
@use 'utils' as *;

@mixin tag-input($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  $bgColor: mat.get-color-from-palette($accent, 500);
  $textColor: mat.get-color-from-palette($accent, A100);

  mg-tag-input {
    .mat-chip:not(.mat-basic-chip) {
      background-color: $bgColor;
      color: $textColor;
    }

    .mat-chip:not(.mat-basic-chip) .mat-chip-remove {
      color: $textColor;
    }
  }
}
