// --- Dependencies ---
@use '@angular/material' as mat;
@use './utils' as *;
@use './vendors/normalize';
@use './components';

// --- Fonts ---
@import url('https://fonts.googleapis.com/css?family=Muli:400,700,900');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap');

// --- Angular Material Config ---
@include mat.core($primary-typography);
@include mat.core-theme($minga-app-blue-theme);
@include mat.all-component-typographies($primary-typography);
@include mat.all-component-themes($minga-app-blue-theme);
@include mat.autocomplete-theme($minga-app-purple-theme);
@include mat.snack-bar-theme($minga-app-purple-theme);
@include mat.button-theme($minga-app-button-theme);
@include mat.slide-toggle-theme($minga-app-form-theme);
@include mat.select-theme($minga-app-form-theme);
@include mat.input-theme($minga-app-form-theme);
@include mat.form-field-theme($minga-app-form-theme);
@include mat.list-theme($minga-app-form-theme);
@include mat.tabs-theme($minga-app-form-theme);
@include mat.progress-spinner-theme($minga-app-progress-spinner-theme);
@include mat.radio-theme($minga-app-form-theme);

// --- Resets ---
@include normalize.reset-css;

// --- Global styles ---
@include components.global;

// --- Helper classes ---
@include components.helper-utils;

// --- Components ---
// iOS Fixes
@include components.ios;
// Splash Screen
@include components.splashscreen;
// Table
@include components.table;
@include components.list-table;
@include components.data-table;
// Text
@include components.text;
// Landing
@include components.landing($minga-white-theme, rgba(0, 0, 0, 0.15));
// Bubble Button
@include components.bubble-btn;
// Tooltip
@include components.tooltip;
// Button
@include components.button($minga-app-button-theme);
// Overlays
@include components.overlay($minga-app-button-theme);
@include components.primary-modal-overlay;
// Navigation
@include components.navigation;
@include components.navicon($minga-app-form-theme);
@include components.navbar(
  $minga-app-form-theme,
  mat.get-color-from-palette($md-compgrey-light, 500)
);
@include components.navitem($minga-app-button-theme);
// Inputs
@include components.default-input(
  $minga-app-form-theme,
  mat.get-color-from-palette($md-compgrey, 500),
  mat.get-color-from-palette($md-textgrey, 500)
);
@include components.input(
  $minga-app-form-theme,
  $minga-app-white,
  $minga-app-form-warm
);
// Search
@include components.search(
  $minga-app-form-theme,
  mat.get-color-from-palette($md-compgrey, 500),
  mat.get-color-from-palette($md-textgrey, 500)
);
// Dialog
@include components.dialog(
  $minga-app-blue-theme,
  $minga-app-form-warm,
  mat.get-color-from-palette($md-black, 500)
);
// Snackbar
@include components.snackbar($minga-app-purple-theme);
// Content
@include components.content($minga-app-content-dark-theme);
@include components.content-input($minga-app-form-theme);
@include components.comment-view($minga-app-form-theme);
// Quill
@include components.quill;
// ngselect
@include components.ngselect;
// Calendar
@include components.calendar($minga-app-calendar-theme);
// Message
@include components.message;
@include components.message-dialog($minga-app-form-theme);
// Misc
@include components.usercontent;
@include components.popover;
@include components.odometer($minga-app-blue-theme);
@include components.top-of-feed($minga-app-calendar-theme);
@include components.pill-checkbox($minga-app-grey-form-theme);
@include components.page($minga-page-theme);
@include components.form-field;
@include components.expansion-panel;
@include components.tab-group-header;
@include components.video-thumbnail;
@include components.short-card(
  $theme: $minga-app-content-theme,
  $darkTheme: $minga-app-content-dark-theme,
  $lightTheme: $minga-white-theme,
  $lightLinkColor: #fff
);
@include components.tag-input($minga-app-form-theme);
@include components.generate-width-utils;
@include components.base-form;
@include components.generate-app-colors;
@include components.profile-picture;

// Material Components Overrides
@include components.material-icons;
@include components.material-slide-toggle;
@include components.material-form;
@include components.material-menu;
@include components.material-tabs-group;
@include components.material-accordian;
@include components.material-chip;
@include components.material-checkbox;
@include components.material-cdk-overlays;
@include components.material-button-toggle-group;
@include components.material-radio;
@include components.material-datepicker;
@include components.material-bottom-sheet;
